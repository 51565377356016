import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '@/reducers';
import * as Sentry from '@sentry/browser';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './assets/icon-dist/CFCIcons.css';
import './assets/icon-dist/FMGIcons.css';
import App from './containers/App';
import { composeWithDevTools } from 'redux-devtools-extension';
import FlagProvider from '@unleash/proxy-client-react';
import ChakraProviderContainer from '@/containers/chakraProvider/ChakraProviderContainer';
import { createUnleashClient } from '@/components/unleash/UnleashClassFlagProvider';
import ChangeReqForProvider from '@/components/ncrs/Context/ChangeReqForContext';
import process from 'process';
import { Toaster } from '@/components/ui/toaster';

window.process = process;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    environment: process.env.REACT_APP_SENTRY_ENV,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <ChakraProviderContainer>
      <FlagProvider unleashClient={createUnleashClient()}>
        <ChangeReqForProvider>
          <App />
          <Toaster />
        </ChangeReqForProvider>
      </FlagProvider>
    </ChakraProviderContainer>
  </Provider>
);

// Note
// User access information needs to be changed in App.js and Sidebar.js to work correctly
