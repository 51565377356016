import { isBoolean, isString } from 'lodash';
import embedVideoLib from 'embed-video';
import { convertToCamelCase } from './helpers';
import Constants from './Constants';
import moment from 'moment';

export function commify(n, decimalPlaces) {
  let endDecimals = false;

  if (n) {
    let number = null;

    if (decimalPlaces) {
      number = n.toFixed(decimalPlaces);
    } else {
      number = Math.ceil(n * 100) / 100;
    }

    [, endDecimals] = number.toString().split('.');
  }
  const neg = n < 0;
  let absN = Math.abs(neg ? Math.ceil(n) : Math.floor(n));
  if (absN < 1000) {
    absN = neg ? `-${absN}` : absN;
    absN = endDecimals ? `${absN}.${endDecimals}` : absN;
    return absN;
  }
  absN += '';

  // Support fractions.
  let i = absN.indexOf('.');
  const f = i === -1 ? '' : absN.slice(i);
  if (f) absN = absN.slice(0, i);

  // Add commas.
  i = absN.length;
  absN = absN.split('');
  while (i > 3) absN.splice((i -= 3), 0, ',');
  absN = absN.join('') + f;
  absN = neg ? `-${absN}` : absN;
  absN = endDecimals ? `${absN}.${endDecimals}` : absN;
  return absN;
}

export function shorten(n) {
  const absN = Math.abs(n);
  const neg = n < 0;
  if (absN < 1000) {
    const toReturn = (neg ? '-' : '') + absN.toFixed(2);
    return `${toReturn}`;
  }
  if (absN > 1000000) {
    const toReturn = `${(neg ? '-' : '') + (absN / 1000000).toFixed(2)}M`;
    return toReturn;
  }
  if (absN >= 1000) {
    const toReturn = `${(neg ? '-' : '') + (absN / 1000).toFixed(2)}K`;
    return toReturn;
  }

  return n;
}

export function formattedValue(value) {
  const newValue = shorten(value);

  return newValue.endsWith('K') || newValue.endsWith('M') ? newValue : `${newValue}M`;
}
/**
 * Calculate Group Housed Premium Rate
 *
 * This function automatically populates the Group Housed Premium rate based on
 * the selected product category, establishment (packer plant), and grinder.
 *
 * The premium rate is determined by matching the following criteria:
 * 1. **Establishment (Packer Plant ID)**: The ID of the packer plant where the product is processed.
 * 2. **Grinder UID**: The unique identifier of the grinder being used.
 * 3. **Product Category and Sub-Category**: These are derived from the selected product and are used to ensure the correct premium rate is applied.
 *
 * Note: it requires matching three criteria instead of two.
 *
 * If a matching configuration is found in the `group_housed_premium_configs`,
 * the corresponding cost is assigned to the `groupHousedPremiumRate` property
 * of the current line item. If no match is found, the premium rate is set to 0
 * or a default value to handle the absence of a valid configuration.
 */
export const calculateGroupHousedPremiumRate = (formCopy, group_housed_premium_configs, input_products) => {
  if (formCopy.packerPlantId !== '' && formCopy.grinderUid !== '') {
    const purchaseOrderDeliveryDate = new Date(formCopy.deliveryDate);
    if (Array.isArray(formCopy.lines)) {
      formCopy.lines.forEach((line, index) => {
        const productUid = line.inputProductUid;
        const product = input_products.find(prdct => prdct.uid === productUid);
        if (product) {
          const config = group_housed_premium_configs.find(
            cnfg =>
              cnfg.packer_plant_id === formCopy.packerPlantId &&
              cnfg.grinder_uid === formCopy.grinderUid &&
              cnfg.product_category === product.category &&
              cnfg.product_sub_category === product.sub_category &&
              new Date(cnfg.delivery_date_from) <= purchaseOrderDeliveryDate // Check delivery date
          );

          if (config) {
            // eslint-disable-next-line no-param-reassign
            formCopy.lines[index].groupHousedPremiumRate = config.cost;
          } else {
            // eslint-disable-next-line no-param-reassign
            formCopy.lines[index].groupHousedPremiumRate = 0;
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          formCopy.lines[index].groupHousedPremiumRate = 0;
        }
      });
    }
  }
  return formCopy; // Return the modified formCopy
};

export function selectValue(formValues, type, e, formProps, index) {
  const formCopy = { ...formValues };
  const { packer_plants, freight_rate, group_housed_premium_configs, input_products } = formProps;

  if (type.toLowerCase().indexOf('date') > -1) {
    if (e) {
      const formattedVal = e.format ? e.format('YYYY-MM-DD') : e;
      if (typeof index !== 'undefined') {
        formCopy.lines[index][type] = formattedVal;
      } else {
        formCopy[type] = formattedVal;
      }
    } else if (typeof index !== 'undefined') {
      formCopy.lines[index][type] = '';
    } else {
      formCopy[type] = '';
    }
    // eslint-disable-next-line no-underscore-dangle
  } else if (e != null && e._isAMomentObject) {
    if (type.indexOf('date') > -1 || type.toLowerCase().indexOf('date') > -1) {
      formCopy[type] = e.format('YYYY-MM-DD');
    } else {
      formCopy[type] = e.format('HH:mm');
    }
  } else if (type === 'estId' || type === 'packerPlantId') {
    if (e) {
      if (e.target) {
        if (e.target.value === '') {
          formCopy.estId = '';
          formCopy.packerPlantId = '';
        } else {
          formCopy[type] = e.target.value;
          const correctPacker = packer_plants
            .filter(option =>
              option.origin_country
                ? Constants.AMERICA_DOMESTIC_COUNTRIES.includes(option.origin_country.toLowerCase())
                : true
            )
            .find(plant => plant.est === e.target.value);
          if (correctPacker) {
            formCopy.packerPlantId = correctPacker.id;
          } else {
            formCopy.packerPlantId = '';
          }
        }
      } else {
        formCopy[type] = e.value;
        const correctPackerUid = packer_plants.find(pp => pp.name.toUpperCase() === e.label.toUpperCase());

        if (correctPackerUid) {
          formCopy.estId = correctPackerUid.est;
        } else {
          formCopy.estId = '';
        }
      }
    } else {
      if (type === 'packerPlantId') {
        formCopy.estId = '';
      }
      formCopy[type] = '';
    }
  } else if (type === 'ordersPerDayOfWeek') {
    const arrayItem = index;
    formCopy.ordersPerDayOfWeek[arrayItem] = e.target.value;
  } else if (type === 'formulaName') {
    const splitMainValue = e.target.value.split('|');
    formCopy.lines[index][type] = e.target.value;
    const splitValues = splitMainValue[1].split(',');
    const [, formulaDay, formulaDaysAveraged] = splitValues;
    formCopy.lines[index].formulaDay = formulaDay;
    formCopy.lines[index].formulaDaysAveraged = formulaDaysAveraged;
  } else if (e) {
    if (e.target) {
      if (typeof index !== 'undefined') {
        formCopy.lines[index][type] = e.target.value;
      } else {
        formCopy[type] = e.target.value;
      }
    } else if (typeof index !== 'undefined') {
      formCopy.lines[index][type] = e.value;
    } else if (e.value || isBoolean(e.value) || isString(e.value)) {
      formCopy[type] = e.value;
    } else {
      formCopy[type] = e;
    }
  } else if (typeof index !== 'undefined') {
    formCopy.lines[index][type] = '';
  } else {
    formCopy[type] = '';
  }

  if (type === 'transportCostPerUnit') {
    formCopy.transportCostPerUnit = e.target.value;
    return formCopy;
  }

  // populate transport cost for grinder and packer_plant
  if (formCopy.packerPlantId !== '' && formCopy.grinderUid !== '') {
    freight_rate.forEach(function (rate) {
      if (rate.packer_plant_id === formCopy.packer_plant_id && rate.grinder_uid === formCopy.grinderUid) {
        formCopy.transportCostPerUnit = rate.cost.toFixed(4);
      }
    });
  }
  // Calculate Group Housed Premium (GHP) based on the data in our master system:
  // Master Data Management -> Pricing -> Group House Premium configuration
  if (type === 'packerPlantId' || type === 'grinderUid' || type === 'inputProductUid') {
    if (formCopy.productType === 'PORK') {
      calculateGroupHousedPremiumRate(formCopy, group_housed_premium_configs, input_products);
    } else if (Array.isArray(formCopy.lines)) {
      formCopy.lines.forEach(line => {
        // eslint-disable-next-line no-param-reassign
        line.groupHousedPremiumRate = 0;
      });
    }
  }
  return formCopy;
}

export function checkErrors(formValues) {
  let allowedFieldsError = false;
  const ALLOWED_FIELD_NAMES = ['buyQuantity', 'pricePerUnit', 'transportCostPerUnit'];

  Object.keys(formValues).forEach(fieldName => {
    if (ALLOWED_FIELD_NAMES.indexOf(fieldName) > -1) {
      if (isNaN(formValues[fieldName]) || formValues[fieldName] === '') {
        allowedFieldsError = true;
      }
    }
  });

  let inputProductError = false;
  formValues.lines.forEach(thisLine => {
    if (thisLine.inputProductUid === '') {
      inputProductError = true;
    }
  });

  return allowedFieldsError || inputProductError;
}

// This function expects camelCased array for loadSizes
export function findMaxLoadSize({ originCountry, destinationCountry, buyUnitOfMeasureId, loadSizes }) {
  const loadSize = loadSizes.find(
    ls =>
      ls.origin === originCountry && ls.destination === destinationCountry && ls.unitOfMeasure.id === buyUnitOfMeasureId
  );
  if (!loadSize) {
    return null;
  }
  const { maximumLoadSize } = loadSize;
  if (!maximumLoadSize) {
    return null;
  }
  return maximumLoadSize;
}

// Alternative refactoring of validateQuantity() function below
export function checkQuantityValidity({
  originCountry,
  destinationCountry,
  lines,
  fieldName,
  loadSizes,
  buyUnitOfMeasureId,
  lineBuyQuantity,
  lineIndex,
}) {
  const name = fieldName || 'buy_quantity';
  const lineList = Object.values(lines).filter(line => line.active);
  const quantitySum = lineList
    .map(line => (line.idx === lineIndex ? lineBuyQuantity : line[name]))
    .reduce((agg, qty) => agg + parseFloat(qty), 0);
  const maximumLoadSize = findMaxLoadSize({
    originCountry,
    destinationCountry,
    loadSizes,
    buyUnitOfMeasureId,
  });
  if (!maximumLoadSize) return false;
  return parseFloat(quantitySum) > maximumLoadSize;
}

export function validateQuantity(formValues, props, fieldName) {
  const { packerPlantId, grinderUid } = formValues;

  const name = fieldName || 'buy_quantity';

  if (!packerPlantId || !grinderUid) {
    return false;
  }

  const packerPlant = props.packer_plants.find(pp => pp.id === formValues.packerPlantId);
  const grinder = props.grinders.find(g => g.uid === formValues.grinderUid);

  const quantitySum = formValues.lines
    .map(line => line[name])
    .reduce((acc, curr) => parseFloat(acc) + parseFloat(curr));

  // Find Max Load Size is expecting camelCased keys
  const maximumLoadSize = findMaxLoadSize({
    originCountry: packerPlant.origin_country,
    destinationCountry: grinder.destination_country,
    loadSizes: convertToCamelCase(props.load_sizes || []),
    buyUnitOfMeasureId: formValues.buyUnitOfMeasureId,
  });
  if (!maximumLoadSize) return false;
  return parseFloat(quantitySum) > maximumLoadSize;
}

export function validateFields(formValues, fields) {
  let hasError = false;
  for (const fieldName in formValues) {
    if (fields.indexOf(fieldName) > -1) {
      if (formValues[fieldName] === '' || formValues[fieldName] === undefined) {
        hasError = true;
        break;
      }
    }
  }
  return hasError;
}

export function checkDateErrors(formValues) {
  let hasDateSequenceError = false;
  let shipmentDateError = false;
  const momentShipment = moment(formValues.shipmentDate).subtract(1, 'day');
  const momentDelivery = moment(formValues.deliveryDate).subtract(1, 'day');
  const momentProduction = moment(formValues.productionDate).subtract(1, 'day');

  formValues.lines.forEach(function (line) {
    const momentFabrication = moment(line.expectedProductionDate).subtract(1, 'day');
    if (
      formValues.expectedProductionDate !== '' &&
      (momentFabrication.isAfter(momentShipment) ||
        momentFabrication.isAfter(momentDelivery) ||
        momentFabrication.isAfter(momentProduction))
    ) {
      hasDateSequenceError = true;
    }
  });
  if (
    (formValues.shipmentDate !== '' && momentShipment.isAfter(momentDelivery)) ||
    momentShipment.isAfter(momentProduction)
  ) {
    hasDateSequenceError = true;
  }
  if (formValues.shipmentDate !== '' && momentShipment.diff(moment(), 'day') < 0) shipmentDateError = true;
  if (formValues.productionDate !== '' && momentDelivery.isAfter(momentProduction)) {
    hasDateSequenceError = true;
  }
  return { hasDateSequenceError, shipmentDateError };
}

// Functions to help with excel export
function datenum(v, date1904) {
  if (date1904) v += 1462;
  const epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

export function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

export function sheetFromArrayOfArrays(data) {
  const ws = {};
  const range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
  for (let R = 0; R !== data.length; R += 1) {
    for (let C = 0; C !== data[R].length; C += 1) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      const cell = { v: data[R][C] };
      if (cell.v == null) continue;
      const cell_ref = window.XLSX.utils.encode_cell({ c: C, r: R });

      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = window.XLSX.SSF._table[14];
        cell.v = datenum(cell.v);
      } else cell.t = 's';

      ws[cell_ref] = cell;
    }
  }
  if (range.s.c < 10000000) ws['!ref'] = window.XLSX.utils.encode_range(range);
  return ws;
}

export function s2ab(s) {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i !== s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff;
  return buf;
}

export function formatOrder(order, grinders, packer_plants, products) {
  const ordr = JSON.parse(JSON.stringify(order));
  if (ordr.buy_quantity) {
    ordr.buy_quantity = parseInt(ordr.buy_quantity.replace(',', ''), 10);
  }
  if (ordr.price_per_unit) {
    ordr.price_per_unit = parseFloat(ordr.price_per_unit);
  }
  if (ordr.transport_cost_per_unit) {
    ordr.transport_cost_per_unit = parseFloat(ordr.transport_cost_per_unit.replace('$', ''));
  }
  if (ordr.grinder) {
    const correctGrinder = grinders.filter(g => g.name.toLowerCase() === ordr.grinder.toLowerCase())[0];
    ordr.grinder = correctGrinder.uid;
  }
  if (ordr.packer) {
    const correctPacker = packer_plants.filter(p => p.name.toLowerCase() === ordr.packer_plant.toLowerCase())[0];
    ordr.packer_plant = correctPacker.uid;
  }
  if (ordr.ingredient) {
    const correctProduct = products.filter(product => product.name.toLowerCase() === ordr.ingredient.toLowerCase())[0];
    ordr.ingredient = correctProduct.id;
    ordr.ingredient = correctProduct.id;
  }
  if (ordr.expected_production_date) {
    ordr.expected_production_date = ordr.expected_production_date.replace(/\//g, '-');
  }
  if (ordr.production_date) {
    ordr.production_date = ordr.production_date.replace(/\//g, '-');
  }
  if (ordr.shipment_date) {
    ordr.shipment_date = ordr.shipment_date.replace(/\//g, '-');
  }
  if (ordr.delivery_date) {
    ordr.delivery_date = ordr.delivery_date.replace(/\//g, '-');
  }
  return ordr;
}

export const calculateAvgUnitPrice = item => {
  if (item.lines.length) {
    item.avg_unit_price =
      item.lines
        .map(line => line.price_per_unit)
        .reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }) / item.lines.length;
  } else {
    item.avg_unit_price = 0;
  }
};

export const calculateAvgLoadCL = item => {
  if (item.lines.length) {
    const quantitySum = item.lines
      .map(line => parseInt(line.buy_quantity))
      .reduce((prev, curr) => {
        return prev + curr;
      });

    let clTimesQuantity = 0;

    item.lines.forEach(line => {
      clTimesQuantity += parseInt(line.buy_quantity) * line.input_product.cl;
    });
    item.avg_load_cl = clTimesQuantity / quantitySum;
  } else {
    item.avg_load_cl = 0;
  }

  return item.avg_load_cl.toFixed(2);
};

function validatePackerOffer(formValues) {
  const errors = [];

  const momentDeliveryStart = moment(formValues.delivery_start_date).subtract(1, 'day');
  const momentDeliveryEnd = moment(formValues.delivery_end_date).subtract(1, 'day');
  const momentStart = moment(formValues.start_date).subtract(1, 'day');
  const momentEnd = moment(formValues.end_date).subtract(1, 'day');

  // start_date <= delivery_start_date <= expected_production_date <= delivery_date(optional) <= end_date <= delivery_end_date

  if (
    formValues.delivery_start_date !== '' &&
    formValues.delivery_end_date !== '' &&
    momentDeliveryStart.isAfter(momentDeliveryEnd)
  ) {
    errors.push('delivery start date must be on or before delivery end date');
  }

  if (
    formValues.start_date !== '' &&
    formValues.delivery_start_date !== '' &&
    momentStart.isAfter(momentDeliveryStart)
  ) {
    errors.push('start date must be on or before delivery start date');
  }

  if (formValues.start_date !== '' && formValues.end_date !== '' && momentStart.isAfter(momentEnd)) {
    errors.push('start date must be on or before end date');
  }

  return errors;
}

export function validatePackerOfferLines(form, lines) {
  const ALLOWED_NUMBER_OF_BINS = 22;

  const errors = [];
  const momentDeliveryEnd = moment(form.delivery_end_date).subtract(1, 'day');
  let totalNumberOfBins = 0;

  lines.forEach((line, index) => {
    const momentFabrication = moment(line.expected_production_date).subtract(1, 'day');

    if (line.expected_production_date !== '' && momentFabrication.isAfter(momentDeliveryEnd)) {
      errors.push(`line ${index + 1}: fabrication date must be on or before delivery end date`);
    }

    if (line.expected_production_date !== '' && form.order_delivery_date) {
      const momentPODeliveryDate = moment(form.order_delivery_date).subtract(1, 'day');

      if (momentFabrication.isAfter(momentPODeliveryDate)) {
        errors.push(`line ${index + 1}: fabrication date must be on or before purchase order delivery date`);
      }
    }
    totalNumberOfBins += line.number_of_bins;
  });

  if (totalNumberOfBins > ALLOWED_NUMBER_OF_BINS) {
    errors.push(`total number of bins is greater than ${ALLOWED_NUMBER_OF_BINS} bins (44,000 lbs)`);
  }

  return errors;
}

export function validatePackerOfferForm(formValues) {
  const errors = validatePackerOffer(formValues);
  return errors.concat(validatePackerOfferLines(formValues, formValues.lines));
}

export function validateConfirmPackerOfferForm(formValues, packerOffer) {
  const errors = [];

  const momentPODelivery = moment(formValues.delivery_date).subtract(1, 'day');
  const momentDeliveryStart = moment(packerOffer.delivery_start_date).subtract(1, 'day');
  const momentDeliveryEnd = moment(packerOffer.delivery_end_date).subtract(1, 'day');

  if (!momentPODelivery.isBetween(momentDeliveryStart, momentDeliveryEnd)) {
    errors.push(
      `delivery date must be between ${packerOffer.delivery_start_date} and ${packerOffer.delivery_end_date}`
    );
  }

  for (const line in packerOffer.lines) {
    const momentFabricationDate = moment(line.delivery_start_date).subtract(1, 'day');

    if (momentPODelivery.isSameOrBefore(momentFabricationDate)) {
      errors.push('delivery date must be after fabrication dates of packer_plant offer lines');
      break;
    }
  }
  return errors;
}

export function getClearedPurchaseOrderLine(line) {
  const form = {
    input_product_uid: '',
    number_of_bins: 0,
    price_per_unit: 0,
    expected_production_date: null,
    price_type: '',
  };
  Object.keys(form).forEach(key => {
    form[key] = line[key] || '';
  });

  return form;
}

export function getClearedPurchaseOrderLines(lines) {
  const clearedLines = [];

  lines.forEach(line => {
    clearedLines.push(getClearedPurchaseOrderLine(line));
  });

  return clearedLines;
}

export function getQueryString(filters) {
  let queryString = '?';

  Object.keys(filters).forEach(key => {
    if (filters[key]) {
      queryString += `${key}=${filters[key]}&`;
    }
  });

  if (queryString === '?') {
    return null;
  }
  return queryString.slice(0, -1);
}

function embedVideo() {
  const embdVideo = embedVideoLib;

  // override default methods to return URL instead of iframe html
  embdVideo.vimeo = id => {
    return `https://player.vimeo.com/video/${id}`;
  };
  embdVideo.youtube = id => {
    return `https://www.youtube.com/embed/${id}`;
  };

  return embdVideo;
}

function isValidUrl(url) {
  const REGEXP = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
  return REGEXP.test(url);
}

export function getEmbedUrl(url) {
  const ALLOWED_SERVICES = ['youtube', 'vimeo'];
  const INVALID_MESSAGE = 'Invalid URL. We support only embedded videos from vimeo and youtube.';
  const embed = embedVideo();

  if (!isValidUrl(url)) {
    throw INVALID_MESSAGE;
  }

  const info = embed.info(url);

  if (url.indexOf('embed') > -1 || url.indexOf('player.vimeo') > -1) {
    return url;
  }
  if (!info || !ALLOWED_SERVICES.includes(info.source)) {
    throw INVALID_MESSAGE;
  }

  return embed(url);
}

export function hasNonEmptyValues(form, requiredFieldNames) {
  for (const fieldName in requiredFieldNames) {
    if (form.hasOwnProperty(fieldName)) {
      if (Array.isArray(form[fieldName] && form[fieldName].length === 0)) {
        return false;
      }

      if (form[fieldName] === '') {
        return false;
      }
    }
  }
  return true;
}

export function hasValuesInRange(form, minRange, maxRange, fieldNames) {
  for (const fieldName of fieldNames) {
    if (!isValueInRange(form, minRange, maxRange, form[fieldName])) {
      return false;
    }
  }
  return true;
}

export function isValueInRange(form, minRange, maxRange, value) {
  return Math.round(Number(value)) >= minRange && Math.round(Number(value) <= maxRange);
}

export function createCurrencyStringValue(inputValue) {
  if (isNaN(inputValue)) return null;

  let price = commify(inputValue, 4);

  const decs = price.toString().split('.');

  if (decs.length === 2 && decs[1] === '0000') {
    price = `${decs[0]}.00`;
  }

  const priceString = `$ ${price}`;

  return priceString;
}

export function capitalizeFirstLetter(value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function toFixed2(value) {
  return Math.round(value * 100) / 100;
}

export function sortInAlphabeticalOrder(a, b) {
  const wordA = a.toLowerCase();

  const wordB = b.toLowerCase();
  if (wordA < wordB)
    // sort string ascending
    return -1;
  if (wordA > wordB) return 1;
  return 0; // default return value (no sorting)
}

export const formatMonetaryValue = (currency, value, formatOptions = {}) => {
  const currencyCode = currency || 'USD';
  const currencySymbol = Constants.CURRENCY_SYMBOLS[currencyCode] || '$';
  if (!isNaN(parseFloat(value))) {
    const formattedValue = new Intl.NumberFormat(undefined, {
      maximumFractionDigits: 2,
      ...formatOptions,
    }).format(value);
    return currency ? `${currencyCode}${currencySymbol} ${formattedValue}` : `${currencySymbol}${formattedValue}`;
  }
  return '';
};

export const wrapDispatch = (dispatch, action) => {
  return new Promise(resolve => {
    dispatch(action).finally(() => {
      resolve();
    });
  });
};

export function isEmptyObject(obj) {
  const props = Object.keys(obj);
  for (let index = 0; index < props.length; index += 1) {
    const prop = props[index];
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}

export const removeEmptyValues = (obj, nullableFields = []) => {
  const keys = Object.keys(obj);
  for (let index = 0; index < keys.length; index += 1) {
    const key = keys[index];
    const value = obj[key];
    if (
      (value === null ||
        value === undefined ||
        (typeof value === 'object' && isEmptyObject(value)) ||
        (Array.isArray(value) && value.length === 0)) &&
      !nullableFields.includes(key)
    ) {
      // eslint-disable-next-line no-param-reassign
      delete obj[key];
    } else if (value && typeof value === 'object' && !Array.isArray(value)) removeEmptyValues(value, nullableFields);
  }
  return obj;
};
