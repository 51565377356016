import get from 'lodash/get';
import Constants from './Constants';
import { defaultTo } from 'lodash';
import { useEffect, useRef } from 'react';

export function toCamel(s) {
  return s.replace(/([-_][a-z])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '');
  });
}

export function toSnake(s) {
  return s.replace(/([A-Z])/g, '_$1').toLowerCase();
}

export function isObject(o) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
}

export function splitCamelCaseString(s) {
  if (s) return s.replace(/([a-z])([A-Z])/g, '$1 $2');
  return s;
}

export function splitSnakeCaseString(s) {
  if (s) return s.replace(/_/g, ' ');
  return s;
}

// Sampled from https://matthiashager.com/converting-snake-case-to-camel-case-object-keys-with-javascrip
export function convertToCamelCase(o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = convertToCamelCase(o[k]);
    });

    return n;
  }
  if (Array.isArray(o)) {
    return o.map(i => convertToCamelCase(i));
  }
  return o;
}

export function convertToSnakeCase(o) {
  if (isObject(o)) {
    const n = {};

    Object.keys(o).forEach(k => {
      n[toSnake(k)] = convertToSnakeCase(o[k]);
    });

    return n;
  }
  if (Array.isArray(o)) {
    return o.map(i => convertToSnakeCase(i));
  }
  return o;
}

export function getOr(obj, path, def) {
  const val = get(obj, path);
  return defaultTo(val, def);
}

export function nestedGetOr(startIndex, paths, obj, defaultValue) {
  const numOfPaths = paths.length;
  if (startIndex === numOfPaths) return defaultValue;
  return getOr(obj, paths[startIndex], nestedGetOr(startIndex + 1, paths, obj, defaultValue));
}

export function iff(condition, then, otherwise) {
  return condition ? then : otherwise;
}

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function truncateStartAndEnd(str, truncLimit = 35) {
  if (str.length > truncLimit) {
    return `${str.substr(0, 20)}...${str.substr(str.length - 10, str.length)}`;
  }
  return str;
}

export function replaceObjectInArray(allObjects, editedObject, idKey = 'id') {
  const newObjects = [];
  allObjects.forEach(element => {
    if (element[idKey] === editedObject[idKey]) {
      newObjects.push(editedObject);
    } else {
      newObjects.push(element);
    }
  });
  return newObjects;
}

export function replaceObjectsInArray(objectList, newObjectList) {
  const objects = Array.isArray(objectList) ? objectList : [];
  const newObjects = Array.isArray(newObjectList) ? newObjectList : [];
  const updatedObjects = objects.map(obj => {
    const newObject = newObjects.find(newObj => newObj.id === obj.id);
    return newObject || obj;
  });
  return updatedObjects;
}

export function replacePoLogsInState(allObjects, editedObject) {
  const newObjects = [];
  allObjects.forEach(element => {
    if (element.purchase_order_id === editedObject.purchase_order_id) {
      newObjects.push(editedObject);
    } else {
      newObjects.push(element);
    }
  });

  return newObjects;
}

export function removeObjectFromArray(allObjects, removedObject, idKey = 'id') {
  const newObjects = [];
  allObjects.forEach(element => {
    if (element[idKey] !== removedObject[idKey]) {
      newObjects.push(element);
    }
  });
  return newObjects;
}

export function strip(html) {
  const tmp = document.createElement('div');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}

export function formatString(text, replacements) {
  return Object.keys(replacements).reduce((old, key) => {
    const searchStr = `\\\${${key}}`;
    return old.replace(new RegExp(searchStr, 'g'), replacements[key]);
  }, text);
}

export function findForeignItem(collection, itemUid) {
  const index = collection.findIndex(item => item.uid === itemUid);
  return index > -1 ? collection[index] : null;
}

export function getPacker(packerUid, packersList) {
  const packerPlant = packersList.find(x => x.uid === packerUid);

  if (packerPlant !== null) {
    return {
      packer_plant_id: packerPlant.id,
      packer_name: packerPlant.name,
      est_num: packerPlant.est,
      origin_country: packerPlant.origin_country,
    };
  }
  return null;
}

export function getGrinder(grinderUid, grinderList) {
  const grinder = grinderList.find(x => x.uid === grinderUid);

  if (grinder !== null) {
    return {
      grinder_uid: grinder.uid,
      grinder_name: grinder.name,
    };
  }

  return null;
}

export function isBuyerOrganisation(user) {
  if (user.businessUse) {
    return 'buyer' in user.businessUse;
  }
  // Delete once buyer is set on all users
  return true;
}

export function togglePortOfLoadingDisplay(packers, baseForm) {
  const portOfLoadingIncoTermsRules = ['FAS', 'DCT', 'FOB'];
  const selectedPacker = packers.find(packer => packer.id === baseForm.packerId) || {};
  if (baseForm.buyIncoterms) {
    return portOfLoadingIncoTermsRules.includes(baseForm.buyIncoterms);
  }
  return portOfLoadingIncoTermsRules.includes(get(selectedPacker, 'defaultBuyIncoterms', ''));
}

export function constructBuyerOptions(users, currentUser) {
  const options = users.map(el => ({
    value: el.id,
    label: `${el.first_name || el.firstName} ${el.last_name || el.lastName}`,
  }));
  if (!options.map(o => o.value).includes(currentUser.id)) {
    options.push({
      value: currentUser.id,
      label: `${currentUser.first_name || currentUser.firstName} ${currentUser.last_name || currentUser.lastName}`,
    });
  }
  return options || [];
}

export function checkForAsianGrinder(grinderCountry) {
  if (!grinderCountry || !grinderCountry.toLowerCase) return false;

  switch (grinderCountry.toLowerCase()) {
    case Constants.COUNTRIES.USA:
    case Constants.COUNTRIES.CANADA:
    case Constants.COUNTRIES.AUSTRALIA:
    case Constants.COUNTRIES.NEW_ZEALAND:
      return false;
    case Constants.COUNTRIES.CHINA:
    case Constants.COUNTRIES.MALAYSIA:
    case Constants.COUNTRIES.TAIWAN:
    case Constants.COUNTRIES.UAE:
    case Constants.COUNTRIES.KOREA:
    case Constants.COUNTRIES.JAPAN:
    case Constants.COUNTRIES.PHILIPINES:
    default:
      return true;
  }
}

export function checkForEuGrinder(grinderCountry){
  switch(grinderCountry.toLowerCase()){
    case Constants.COUNTRIES.ALBANIA:
    case Constants.COUNTRIES.ANDORRA:
    case Constants.COUNTRIES.ARMENIA:
    case Constants.COUNTRIES.AUSTRIA:
    case Constants.COUNTRIES.AZERBAIJAN:
    case Constants.COUNTRIES.BELARUS:
    case Constants.COUNTRIES.BELGIUM:
    case Constants.COUNTRIES.BOSNIA_AND_HERZEGOVINA:
    case Constants.COUNTRIES.BULGARIA:
    case Constants.COUNTRIES.CROATIA:
    case Constants.COUNTRIES.CYPRUS:
    case Constants.COUNTRIES.CZECH_REPUBLIC:
    case Constants.COUNTRIES.DENMARK:
    case Constants.COUNTRIES.ESTONIA:
    case Constants.COUNTRIES.FINLAND:
    case Constants.COUNTRIES.FRANCE:
    case Constants.COUNTRIES.GEORGIA:
    case Constants.COUNTRIES.GERMANY:
    case Constants.COUNTRIES.GREECE:
    case Constants.COUNTRIES.HUNGARY:
    case Constants.COUNTRIES.ICELAND:
    case Constants.COUNTRIES.IRELAND:
    case Constants.COUNTRIES.ITALY:
    case Constants.COUNTRIES.LATVIA:
    case Constants.COUNTRIES.LITHUANIA:
    case Constants.COUNTRIES.LUXEMBOURG:
    case Constants.COUNTRIES.MALTA:
    case Constants.COUNTRIES.MOLDOVA:
    case Constants.COUNTRIES.MONACO:
    case Constants.COUNTRIES.MONTENEGRO:
    case Constants.COUNTRIES.NETHERLANDS:
    case Constants.COUNTRIES.NORTH_MACEDONIA:
    case Constants.COUNTRIES.NORWAY:
    case Constants.COUNTRIES.PORTUGAL:
    case Constants.COUNTRIES.ROMANIA:
    case Constants.COUNTRIES.RUSSIA:
    case Constants.COUNTRIES.SAN_MARINO:
    case Constants.COUNTRIES.SERBIA:
    case Constants.COUNTRIES.SLOVAKIA:
    case Constants.COUNTRIES.SLOVENIA:
    case Constants.COUNTRIES.SPAIN:
    case Constants.COUNTRIES.SWEDEN:
    case Constants.COUNTRIES.SWITZERLAND:
    case Constants.COUNTRIES.UKRAINE:
    case Constants.COUNTRIES.VATICAN_CITY:
      return true;
    default:
      return false;
  }
}
/**
 * Formats decimal roundings when number input fields are entered
 * @param  {string} input - html input field
 * @param  {number} scale - decimal scale; default is 2
 * @returns result
 */
export const correctDecimalRoundingChange = (input, scale = 2) => {
  let roundedNumber = null;

  if (typeof input === 'string') {
    const [whole, part] = input.split('.');

    if (input.lastIndexOf('.') !== -1 && part.length > scale) {
      roundedNumber = parseFloat(`${whole}.${part.slice(0, scale)}`);
    }
  }

  const result = roundedNumber != null ? roundedNumber : input;

  return result;
};

/**
 * Formats decimal roundings when number input fields are out of focus
 * @param  {string} input - html input field
 * @param  {number} scale - decimal scale; default is 2
 * @returns {result, roundedNumber}
 */
export const correctDecimalRoundingBlur = (input, scale = 2) => {
  const roundedNumber = parseFloat(input);

  const result = roundedNumber.toFixed(scale);

  return { result, roundedNumber };
};

/** extends js includes to match substrings */
export function isExistsInArray(arrayObj, substring) {
  const matchedWords = [];
  arrayObj.forEach(word => {
    const substringRegex = new RegExp(substring, 'gi');
    if (word.match(substringRegex)) {
      matchedWords.push(word);
    }
  });
  return matchedWords;
}

export function convertValueToLabel(s) {
  // example s = 'usdaAngus' => Usda Angus
  const lst = s.split(/(?=[A-Z])/);
  const cLst = lst.map(el => {
    return el.charAt(0).toUpperCase() + el.slice(1);
  });
  return cLst.join(' ');
}

export const isJSON = str => {
  try {
    const obj = JSON.parse(str);
    if (obj && typeof obj === 'object' && obj !== null) {
      return true;
    }
  } catch (err) {
    return false;
  }
  return false;
};

export const validateSelectableContractStartDate = (current, contractEndDate) => {
  return contractEndDate ? current.isBefore(contractEndDate) : true;
};

export const validateSelectableContractEndDate = (current, contractStartDate) => {
  return contractStartDate ? current.isAfter(contractStartDate) : true;
};

export const getStatusColor = (status = '') => {
  switch (status.toLowerCase()) {
    case 'active':
      return { color: '#4CAF50', bgColor: '#F1FFF2' };
    case 'expired':
      return { color: '#AD6149', bgColor: '#FFE4E4' };
    case 'expiring soon':
      return { color: '#B6671E', bgColor: '#FFF8F1' };
    case 'deactivated':
      return { color: '#FF4D49', bgColor: '#FFEDEC' };
    case 'idle':
      return { color: '#8B8B8B', bgColor: '#EFEFEF' };
    default:
      return { color: '#8B8B8B', bgColor: '#EFEFEF' };
  }
};

// fileUtils.js

export function generateUniqueFileName(originalFileName) {
  const timestamp = new Date().toISOString().replace(/[-:.]/g, ''); // Remove special characters from timestamp
  const uniqueId = Math.random().toString(36).substr(2, 9); // Generate a random unique ID
  const fileExtension = originalFileName.split('.').pop(); // Get the file extension
  const nameWithoutExtension = originalFileName.replace(`.${fileExtension}`, ''); // Remove the extension

  return `${nameWithoutExtension}_${uniqueId}_${timestamp}.${fileExtension}`; // Create the new file name
}