import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Group, Spinner, Text, Center, IconButton } from '@chakra-ui/react';
import ResultsGrid from '../../../components/basic/ResultsGrid';
import DashboardSectionTile from '../../../components/core/DashboardSectionTile';
import './DataImportPreview.scss';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { MdArrowBack, MdWarning } from 'react-icons/md';

const DataImportPreview = ({
  previewTableData,
  summaryTableData,
  onClickBackButton,
  onClickConfirmButton,
  onClickFinishButton,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const getPOsCount = () => {
    return new Set(summaryTableData.rows.map(row => row.po_id)).size;
  };

  return (
    <>
      <DashboardSectionTile
        title={
          <>
            Preview Upload&nbsp;
            <Tooltip
              content="Display a preview of the fields uploaded in the CSV or Excel file for verification"
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        }
        padding="22px 16px 49px 15px"
      >
        {previewTableData && (
          <Box marginLeft="10px !important">
            <ResultsGrid
              id="DataImportPreviewUpload"
              className="data_import_preview_results_grid"
              {...previewTableData}
              minHeight="fit-content"
              rowHeight={() => 57}
            />
            <Text fontWeight="700" fontSize="12px" marginTop="23px">
              Total Records: {previewTableData.rows.length}
            </Text>
          </Box>
        )}
      </DashboardSectionTile>
      <DashboardSectionTile
        title={
          <>
            Summary&nbsp;
            <Tooltip
              content="Show a summary of changes, including which fields need to be updated from old values to new values"
              positioning={{ placement: 'right-end' }}
            >
              <IconButton
                width="14px"
                height="14px"
                padding="0"
                minW="auto"
                borderRadius="50%"
                color="#878787"
                variant="unstyled"
              >
                <IoInformationCircleOutline size="14px" />
              </IconButton>
            </Tooltip>
          </>
        }
        padding="22px 16px 49px 15px"
        marginTop="55px"
      >
        {summaryTableData && (
          <Box marginLeft="10px !important">
            <ResultsGrid
              id="DataImportPreviewSummary"
              className="data_import_preview_results_grid"
              {...summaryTableData}
              minHeight="fit-content"
              rowHeight={() => 57}
            />
          </Box>
        )}
        {!summaryTableData && (
          <Center>
            <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="actionSecondary.default" size="xl" />
          </Center>
        )}
      </DashboardSectionTile>
      <Group
        colorScheme="actionPrimary"
        justifyContent="flex-end"
        width="100%"
        marginTop="39px"
        fontSize="16px"
        gap="15px"
      >
        {onClickBackButton && (
          <Button variant="outline" width="117px" onClick={onClickBackButton} fontSize="inherit" fontWeight="600">
            <MdArrowBack />
            Back
          </Button>
        )}
        {onClickConfirmButton && (
          <Button
            width="110px"
            disabled={!summaryTableData?.rows?.length}
            fontSize="inherit"
            fontWeight="900"
            onClick={onOpen}
          >
            Confirm
          </Button>
        )}
        {onClickFinishButton && (
          <Button width="110px" onClick={onClickFinishButton} fontSize="inherit" fontWeight="900">
            Finish
          </Button>
        )}
      </Group>
      {summaryTableData && previewTableData ? (
        <DialogRoot open={isOpen} onClose={onClose} centered>
          <DialogContent minWidth="600px">
            <DialogHeader fontSize="20px" borderBottomWidth="1px">
              <MdWarning color="#545D69" /> &nbsp;Do you wish to continue?
            </DialogHeader>
            <DialogCloseTrigger asChild top="12px" onClick={onClose} />
            <DialogBody>
              <Center minHeight="264px">
                <Text fontSize="18px">
                  You are about to make <b>{summaryTableData.rows.length} changes</b> to{' '}
                  <b>{getPOsCount()} PO&rsquo;s.</b>
                </Text>
              </Center>
            </DialogBody>
            <DialogFooter borderTopWidth="1px">
              <Group colorScheme="actionPrimary" marginLeft="auto" fontSize="16px" fontWeight="900" gap="9px">
                <Button
                  variant="outline"
                  fontSize="inherit"
                  fontWeight="inherit"
                  height="40px"
                  width="130px"
                  onClick={onClose}
                  backgroundColor="gray.200" 
                  color="#333"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    onClose();
                    onClickConfirmButton();
                  }}
                  fontSize="inherit"
                  fontWeight="inherit"
                  height="40px"
                  width="126px"
                >
                  Continue
                </Button>
              </Group>
            </DialogFooter>
          </DialogContent>
        </DialogRoot>
      ) : (
        ''
      )}
    </>
  );
};

DataImportPreview.propTypes = {
  previewTableData: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        po_id: PropTypes.string.isRequired,
        // Add other fields as necessary
      })
    ).isRequired,
  }).isRequired,
  summaryTableData: PropTypes.shape({
    rows: PropTypes.arrayOf(
      PropTypes.shape({
        po_id: PropTypes.string.isRequired,
        // Add other fields as necessary
      })
    ).isRequired,
  }).isRequired,
  onClickBackButton: PropTypes.func,
  onClickConfirmButton: PropTypes.string,
  onClickFinishButton: PropTypes.func,
};

export default DataImportPreview;
