import { Box, Tabs } from '@chakra-ui/react';
import HorizontalNavigationBand from '../../components/core/HorizontalNavigationBand';

import React, { useState } from 'react';
import DocumentsQueue from './DocumentsQueue/DocumentsQueue';
import './email-attachment.scss';
import DocumentTypes from './DocumentTypes/DocumentTypes';
import DocumentsQueueTester from './DocumentsQueueTester/DocumentsQueueTester';

const tab = {
  documentsQueue: 'Documents Queue',
  documentTypes: 'Document Types',
  documentsQueueTester: 'Tester',
};
const tabs = [tab.documentsQueue, tab.documentTypes, tab.documentsQueueTester];

export default () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <Box style={{ display: 'grid' }}>
      <Tabs.Root defaultValue={tabs[0]}>
        <HorizontalNavigationBand justifyContent="flex-start" paddingX="52px">
          <Tabs.List>
            {tabs.map(tb => (
              <Tabs.Trigger
                value={tb}
                key={tb}
                _focus={{ outline: 'none' }}
                _selected={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                _hover={{ borderColor: 'secondary.800', borderBottomWidth: '2px' }}
                fontWeight="normal"
                width="162px"
                fontSize="14px"
                padding="0px"
                height="103px"
                marginRight="10px"
                onClick={() => setActiveTab(tb)}
              >
                {tb}
              </Tabs.Trigger>
            ))}
          </Tabs.List>
        </HorizontalNavigationBand>

        <Tabs.Content value={tab.documentsQueue}>{activeTab === tab.documentsQueue && <DocumentsQueue />}</Tabs.Content>
        <Tabs.Content value={tab.documentTypes}>{activeTab === tab.documentTypes && <DocumentTypes />}</Tabs.Content>
        <Tabs.Content value={tab.documentsQueueTester}>
          {activeTab === tab.documentsQueueTester && <DocumentsQueueTester />}
        </Tabs.Content>
      </Tabs.Root>
    </Box>
  );
};
