import { connect } from 'react-redux';
import Release from '../../components/coldstore/release-management/Release';
import { findEntitiesInState } from '../../slices/masterData/entityManagerSlice';

const mapStateToProps = state => {
  return {
    ...findEntitiesInState(state, {
      grinders: 'grinder',
      packer_plants: 'packer_plant',
      input_products: 'input_product',
      cold_stores: 'coldstore',
      transporters: 'transporter',
    }),
    user: state.user,
    previewOrders: state.coldstore_orders_util.previewOrders,
    orders: state.orders_pending_coldstore_release,
    notifying_action_groups: state.notifying_action_groups,
    coldstoreSendEmailError: state.coldstore_orders_util.coldstoreSendEmailError,
    isColdstoreEmailSent: state.coldstore_orders_util.isColdstoreEmailSent || false,
  };
};

export default connect(mapStateToProps)(Release);
