import {
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Input,
  Spinner,
  Stack,
  Table,
  Textarea,
  Text,
  VStack,
  IconButton,
} from '@chakra-ui/react';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { BsFiletypeJson } from 'react-icons/bs';
import axios from 'axios';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import FileViewer from 'react-file-viewer';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import xlsxViewer from 'xlsx-viewer';
import 'xlsx-viewer/lib/index.css';
import * as Yup from 'yup';
import Constants from '../../../Constants';
import { fetchConfigs } from '../../../actions/actions_config';
import Select from '../../../components/basic/SelectField';
import RightAngleIcon from '../../../components/core/RightAngleIcon';
import { selectConfigs } from '../../../reducers/reducer_config';
import { selectCurrentUser } from '../../../reducers/reducer_user';
import { fetchEntitiesData, selectEntities } from '../../../slices/masterData/entityManagerSlice';
import { getInternalPoLabel } from '../../../utils';
import './documents-queue.scss';
import { DialogContent, DialogRoot, DialogCloseTrigger } from '@/components/ui/dialog';
import { InputGroup } from '@/components/ui/input-group';
import { Field } from '@/components/ui/field';
import { MenuContent, MenuTrigger, MenuRoot, MenuItem } from '@/components/ui/menu';
import { FaSearch, FaChevronDown, FaEye } from 'react-icons/fa';
import { MdArrowBack, MdArrowDownward, MdArrowUpward, MdDownload } from 'react-icons/md';
import { Checkbox } from '@/components/ui/checkbox';
import { toaster } from '@/components/ui/toaster';
import { Avatar } from '@/components/ui/avatar';

const PO_FROM = {
  grinder_po_number: 'grinder_po_number',
  internal_po_number: 'internal_po_number',
  packer_so_number: 'packer_so_number',
};

const EMAIL_ATTACHMENTS_STATUS = {
  pending: 'pending',
  pendingRevision: 'pending-revision',
  completed: 'completed',
  rejected: 'rejected',
  archive: 'archive',
  draft: 'draft',
  all: 'all',
};

const EMAIL_ATTACHMENTS_STATUS_COLOR = {
  [EMAIL_ATTACHMENTS_STATUS.pending]: 'yellow',
  [EMAIL_ATTACHMENTS_STATUS.pendingRevision]: 'yellow',
  [EMAIL_ATTACHMENTS_STATUS.completed]: 'green',
  [EMAIL_ATTACHMENTS_STATUS.rejected]: 'red',
  [EMAIL_ATTACHMENTS_STATUS.archive]: 'yellow',
  [EMAIL_ATTACHMENTS_STATUS.draft]: 'yellow',
};
const STATUS_FILTER = [
  {
    label: 'All',
    value: EMAIL_ATTACHMENTS_STATUS.all,
    icon: active => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M2 8.66667V7.33334H11.3333V8.66667H2ZM2 12.6667V11.3333H11.3333V12.6667H2ZM2 4.66667V3.33334H11.3333V4.66667H2ZM13.3333 5.33334V3.33334H12.6667V2.66667H14V5.33334H13.3333ZM12.6667 11.3333V10.6667H14.6667V13.3333H12.6667V12.6667H14V12.3333H13.3333V11.6667H14V11.3333H12.6667ZM14.1667 6.66667C14.4467 6.66667 14.6667 6.89334 14.6667 7.16667C14.6667 7.30001 14.6133 7.42667 14.5267 7.51334L13.4133 8.66667H14.6667V9.33334H12.6667V8.72001L14 7.33334H12.6667V6.66667H14.1667Z"
          fill={active ? '#3A44B0' : 'black'}
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  {
    label: 'Pending',
    value: EMAIL_ATTACHMENTS_STATUS.pending,
    icon: active => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M2.66671 1.33333C2.31309 1.33333 1.97395 1.4738 1.7239 1.72385C1.47385 1.9739 1.33337 2.31304 1.33337 2.66666V13.3333C1.33337 13.687 1.47385 14.0261 1.7239 14.2761C1.97395 14.5262 2.31309 14.6667 2.66671 14.6667H8.27337C8.99626 15.1011 9.82335 15.3315 10.6667 15.3333C11.9044 15.3333 13.0914 14.8417 13.9665 13.9665C14.8417 13.0913 15.3334 11.9043 15.3334 10.6667C15.3322 9.66263 15.0072 8.68574 14.4067 7.88107C13.8062 7.0764 12.9622 6.48686 12 6.2V5.33333L8.00004 1.33333H2.66671ZM2.66671 2.66666H7.33337V6H10.6667C9.42903 6 8.24205 6.49166 7.36688 7.36683C6.49171 8.242 6.00004 9.42898 6.00004 10.6667C6.00093 11.6205 6.29409 12.5512 6.84004 13.3333H2.66671V2.66666ZM10.6667 7.33333C11.5508 7.33333 12.3986 7.68452 13.0237 8.30964C13.6489 8.93476 14 9.78261 14 10.6667C14 11.5507 13.6489 12.3986 13.0237 13.0237C12.3986 13.6488 11.5508 14 10.6667 14C9.78265 14 8.93481 13.6488 8.30968 13.0237C7.68456 12.3986 7.33337 11.5507 7.33337 10.6667C7.33337 9.78261 7.68456 8.93476 8.30968 8.30964C8.93481 7.68452 9.78265 7.33333 10.6667 7.33333ZM10 8V11.3333L12.4067 12.7733L12.9067 11.96L11 10.8333V8H10Z"
          fill={active ? '#3A44B0' : 'black'}
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  {
    label: 'Completed',
    value: EMAIL_ATTACHMENTS_STATUS.completed,
    icon: active => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M15.6666 11.3333L12.3333 14.6667L9.99996 12.3333L11 11.3333L12.3333 12.6667L14.6666 10.3333L15.6666 11.3333ZM8.72663 13.3333H3.99996V2.66667H8.66663V6H12V8.72667C12.22 8.69334 12.44 8.66667 12.6666 8.66667C12.8933 8.66667 13.1133 8.69334 13.3333 8.72667V5.33334L9.33329 1.33334H3.99996C3.25996 1.33334 2.66663 1.93334 2.66663 2.66667V13.3333C2.66663 14.0733 3.25996 14.6667 3.99996 14.6667H9.20663C8.97329 14.26 8.80663 13.8133 8.72663 13.3333Z"
          fill={active ? '#3A44B0' : 'black'}
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  {
    label: 'Rejected',
    value: EMAIL_ATTACHMENTS_STATUS.rejected,
    icon: active => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M15.0266 14.08L13.6066 12.6667L15.0266 11.2533L14.08 10.3067L12.6666 11.7267L11.2533 10.3067L10.3066 11.2533L11.7266 12.6667L10.3066 14.08L11.2533 15.0267L12.6666 13.6067L14.08 15.0267M3.99996 1.33334C3.25996 1.33334 2.66663 1.93334 2.66663 2.66667V13.3333C2.66663 14.0733 3.25996 14.6667 3.99996 14.6667H9.20663C8.96663 14.2533 8.79996 13.8 8.71996 13.3333H3.99996V2.66667H8.66663V6H12V8.72C12.22 8.68667 12.4466 8.66667 12.6666 8.66667C12.8933 8.66667 13.1133 8.68667 13.3333 8.72V5.33334L9.33329 1.33334M5.33329 8V9.33334H10.6666V8M5.33329 10.6667V12H8.66663V10.6667H5.33329Z"
          fill={active ? '#3A44B0' : 'black'}
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  {
    label: 'Pending Revision',
    value: EMAIL_ATTACHMENTS_STATUS.pendingRevision,
    icon: active => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M3.99996 1.33333C3.64663 1.33333 3.30663 1.47333 3.05996 1.72667C2.80663 1.97333 2.66663 2.31333 2.66663 2.66667V13.3333C2.66663 13.6867 2.80663 14.0267 3.05996 14.2733C3.30663 14.5267 3.64663 14.6667 3.99996 14.6667H8.66663C8.41996 14.2667 8.22663 13.8133 8.11329 13.3333H3.99996V2.66667H8.66663V6H12V8H12.3333C12.6666 8 13 8.04 13.3333 8.11333V5.33333L9.33329 1.33333H3.99996ZM7.99996 12C8.04663 11.5333 8.15996 11.08 8.33329 10.6667H5.33329V12H7.99996ZM9.20663 9.33333C9.61996 8.90667 10.1133 8.56667 10.6666 8.33333V8H5.33329V9.33333H9.20663ZM12 9.66667C12.74 9.66667 13.4066 9.96667 13.8866 10.4467L14.6666 9.66667V12.3333H12L13.18 11.1533C12.88 10.8533 12.46 10.6667 12 10.6667C11.08 10.6667 10.3333 11.4133 10.3333 12.3333C10.3333 13.2533 11.08 14 12 14C12.5466 14 13.0266 13.74 13.3333 13.3333H14.4733C14.08 14.3133 13.12 15 12 15C10.5266 15 9.33329 13.8067 9.33329 12.3333C9.33329 10.86 10.5266 9.66667 12 9.66667Z"
          fill={active ? '#3A44B0' : 'black'}
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  {
    label: 'Save Draft',
    value: EMAIL_ATTACHMENTS_STATUS.draft,
    icon: active => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M5.33329 8.0022H10.6666V9.33553H5.33329V8.0022ZM6.66663 13.3355H3.99996V2.66886H8.66663V6.0022H12V8.06886L13.3333 6.73553V5.33553L9.33329 1.33553H3.99996C3.64634 1.33553 3.3072 1.47601 3.05715 1.72605C2.8071 1.9761 2.66663 2.31524 2.66663 2.66886V13.3355C2.66663 13.6892 2.8071 14.0283 3.05715 14.2783C3.3072 14.5284 3.64634 14.6689 3.99996 14.6689H6.66663V13.3355ZM5.33329 12.0022H8.06663L8.66663 11.4022V10.6689H5.33329V12.0022ZM13.4666 8.66886C13.5333 8.66886 13.6666 8.73553 13.7333 8.8022L14.6 9.66886C14.7333 9.8022 14.7333 10.0689 14.6 10.2022L13.9333 10.8689L12.5333 9.46886L13.2 8.8022C13.2666 8.73553 13.3333 8.66886 13.4666 8.66886ZM13.4666 11.2689L9.39996 15.3355H7.99996V13.9355L12.0666 9.86886L13.4666 11.2689Z"
          fill={active ? '#3A44B0' : 'black'}
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
  {
    label: 'Archive',
    value: EMAIL_ATTACHMENTS_STATUS.archive,
    icon: active => (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path
          d="M13.3334 12H2.66671V5.33333H13.3334M13.3334 4H8.00004L6.66671 2.66667H2.66671C1.92671 2.66667 1.33337 3.26 1.33337 4V12C1.33337 12.3536 1.47385 12.6928 1.7239 12.9428C1.97395 13.1929 2.31309 13.3333 2.66671 13.3333H13.3334C13.687 13.3333 14.0261 13.1929 14.2762 12.9428C14.5262 12.6928 14.6667 12.3536 14.6667 12V5.33333C14.6667 4.59333 14.0667 4 13.3334 4Z"
          fill={active ? '#3A44B0' : 'black'}
          fillOpacity="0.6"
        />
      </svg>
    ),
  },
];
const STATUS_OPTIONS = [
  { label: 'Pending', value: EMAIL_ATTACHMENTS_STATUS.pending },
  { label: 'Pending Revision', value: EMAIL_ATTACHMENTS_STATUS.pendingRevision },
  { label: 'Completed', value: EMAIL_ATTACHMENTS_STATUS.completed },
  { label: 'Rejected', value: EMAIL_ATTACHMENTS_STATUS.rejected },
  { label: 'Archived', value: EMAIL_ATTACHMENTS_STATUS.archive },
];

export default () => {
  const { token } = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const configs = useSelector(selectConfigs);
  const entities = useSelector(selectEntities);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [grinders, setGrinders] = useState([]);
  const [countries, setCountries] = useState([]);
  const [formFields, setFormFields] = useState({});
  // const toast = useToast();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const xlsxViewerEle = useRef();

  const [axiosConfig, setAxiosConfig] = useState();
  const [attachments, setAttachments] = useState();
  const [statusFacet, setStatusFacet] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState();
  const [poNumberFilter, setPoNumberFilter] = useState('');
  const [docTypeFilter, setDocTypeFilter] = useState([]);
  const [prodTypeFilter, setProdTypeFilter] = useState([]);
  const [grinderUidFilter, setGrinderUidFilter] = useState([]);
  const [originCountryFilter, setOriginCountryFilter] = useState([]);
  const [purchasingOfficeFilter, setPurchasingOfficeFilter] = useState([]);
  const [fromEmailAddressFilter, setFromEmailAddressFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState(EMAIL_ATTACHMENTS_STATUS.pending);
  const [sortFilter, setSortFilter] = useState('');
  const [isLoading, setIsLoading] = useState();
  const [selectedAttachment, setSelectedAttachment] = useState();
  const [filePreviewZoom, setFilePreviewZoom] = useState(100);

  const onViewOpen = () => setIsViewOpen(true);
  const onViewClose = () => setIsViewOpen(false);

  const loadAttachments = async () => {
    axios
      .get(
        `${Constants.URL}emails/attachments?page=${
          currentPage + 1
        }&status=${statusFilter}&doc_types=${docTypeFilter.join(',')}&prod_types=${prodTypeFilter.join(
          ','
        )}&from_email_address=${fromEmailAddressFilter}&po_number=${poNumberFilter}&grinder_uids=${grinderUidFilter.join(
          ','
        )}&origin_countries=${originCountryFilter.join(',')}&purchasing_office=${purchasingOfficeFilter.join(
          ','
        )}&sort=${sortFilter}`,
        axiosConfig
      )
      .then(response => {
        setAttachments(response.data.items);
        setPagesCount(response.data.pagesCount);
        setStatusFacet(response.data.facets.status);
      });
  };

  useEffect(() => {
    loadAttachments();
  }, [statusFilter, sortFilter, currentPage]);

  const loadDocumentTypes = async () =>
    axios.get(`${Constants.URL}document-types`, axiosConfig).then(response => setDocumentTypes(response.data));

  const loadGrinders = () =>
    axios
      .get(`${Constants.URL}grinders`, axiosConfig)
      .then(response => setGrinders(response.data.map(g => ({ label: g.name, value: g.uid }))));

  const loadCountries = () =>
    axios
      .get(`${Constants.URL}countries?origin_country=true`, axiosConfig)
      .then(response => setCountries(response.data.map(c => ({ label: c.name, value: c.name }))));

  const handleError = (isSystemError, msg = '') => {
    toaster.create({
      title: 'An error occurred.',
      description: `${isSystemError ? 'Please contact administrator with the screenshot of this error.' : ''}${msg}${
        isSystemError ? ` at ${moment().toISOString()}` : ''
      }`,
      status: 'error',
    });
  };

  const handleSuccess = (msg = '') => {
    toaster.create({
      title: msg,
      status: 'success',
    });
  };

  const setAttachmentLoading = (a, loading) => {
    const ats = [...attachments];
    for (let i = 0; i < ats.length; i += 1) {
      const at = ats[i];
      if (at === a) {
        at.isLoading = loading;
        break;
      }
    }
    setAttachments(ats);
  };

  // SAVE/UPLOAD THE ATTACHMENT
  const saveAttachment = async attachment => {
    setIsLoading(true);
    try {
      const { id, doc_type, po_lookup_field, po_number, notes, is_draft, status } = attachment;
      await axios.patch(
        `${Constants.URL}emails/attachments/${id}`,
        { doc_type, po_lookup_field, po_number, notes, is_draft, status },
        axiosConfig
      );
      await loadAttachments();
      onViewClose();
      handleSuccess('Attachment saved succesfully!');
    } catch (e) {
      if (e.response) {
        handleError(false, e?.response?.data?.error || e);
      } else {
        handleError(true, e);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const { errors, handleChange, handleBlur, setFieldValue, handleSubmit, setValues, values, touched, isValid } =
    useFormik({
      initialValues: {},
      validationSchema: Yup.object().shape({
        id: Yup.number().nullable(),
        doc_type: Yup.string().nullable().required('Required'),
        po_lookup_field: Yup.string().nullable().required('Required'),
        po_number: Yup.string().nullable().required('Required'),
        status: Yup.string().nullable().required('Required'),
        notes: Yup.string().nullable(),
        is_draft: Yup.bool().nullable(),
      }),
      onSubmit: data => saveAttachment(data),
    });

  const openAttachmentView = async a => {
    setAttachmentLoading(a, true);
    try {
      setValues(a);
      const { signed_url } = (await axios.post(`${Constants.URL}signed_url`, [{ url: a.url }], axiosConfig)).data[0];
      const res = await fetch(signed_url);
      if (!res.ok) {
        if (res.status === 404) {
          throw new Error('Could not load the attachment.');
        }
      }
      const extension = a.filename.split('.').slice(-1)[0].toLowerCase();
      const slectedAttachment = { ...a, signedUrl: signed_url, fileType: extension };
      if (extension === 'json') {
        const json = await res.json();
        slectedAttachment.render = 'json-viewer';
        slectedAttachment.content = JSON.stringify(json, null, 2);
      } else if (extension !== 'xlsx') {
        slectedAttachment.render = 'react-file-viewer';
      } else {
        slectedAttachment.render = 'xlsx-viewer';
        const blob = await res.blob();
        setTimeout(() => {
          xlsxViewer.renderXlsx(blob, xlsxViewerEle.current, {
            initialSheetIndex: 0,
            onLoad() {},
            onRender() {},
            onSwitch() {},
          });
        }, 1);
      }
      setFilePreviewZoom(100);
      setSelectedAttachment(slectedAttachment);
      onViewOpen();
    } catch (e) {
      handleError(true, e);
    } finally {
      setAttachmentLoading(a, false);
    }
  };

  const formatNumber = value => {
    // Check if the value is already a string with commas
    if (typeof value === 'string' && value.includes(',')) {
      return value;
    }
    // Convert the value to a number and format it with commas
    const number = value === null ? NaN : Number(value);
    return isNaN(number) ? '-' : number.toLocaleString();
  };

  const windowFeatures = 'width=800,height=800,top=100,left=100,resizable=yes,scrollbars=yes';

  const [leftWidth, setLeftWidth] = useState(60); // Percentage width for the left box

  const handleMouseMove = e => {
    const newWidth = (e.clientX / window.innerWidth) * 100;
    if (newWidth > 10 && newWidth < 90) {
      // Restrict to reasonable ranges
      setLeftWidth(newWidth);
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const openFileViewerInNewWindow = slectedAttachment => {
    const { signedUrl, fileType } = slectedAttachment;

    // Open a new window
    const viewerWindow = window.open('', '_blank', windowFeatures);
    if (!viewerWindow) {
      // eslint-disable-next-line no-console
      console.error('Failed to open new window.');
      return;
    }

    // Basic HTML template for the new window
    viewerWindow.document.write(`
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <title>File Viewer</title>
          <style>
            body { margin: 0; paddingL 0; height: 100%; overfliw: hidden; font-family: Arial, sans-serif; }
            iframe { width: 100%; height: 100vh; border: none; display: block}
          </style>
        </head>
        <body>
          <div id="content">
            <p>Loading file preview...</p>
          </div>
        </body>
      </html>
    `);
    viewerWindow.document.close();

    // Handle file preview based on file type
    const contentElement = viewerWindow.document.getElementById('content');

    if (fileType === 'pdf') {
      // Render PDF in an iframe
      const docsViewer = `https://docs.google.com/viewer?url=${encodeURIComponent(signedUrl)}&embedded=true`;
      contentElement.style.width = '100%';
      contentElement.style.height = '100%';
      contentElement.style.margin = '0';
      contentElement.style.padding = '0';
      contentElement.innerHTML = `
        <iframe src="${docsViewer}" frameborder="0"></iframe>
      `;
    } else if (fileType === 'json') {
      // Render JSON directly in the page
      fetch(signedUrl)
        .then(response => response.json())
        .then(json => {
          contentElement.innerHTML = `
            <pre>${JSON.stringify(json, null, 2)}</pre>
          `;
        })
        .catch(error => {
          contentElement.innerHTML = `<p>Error loading JSON: ${error.message}</p>`;
        });
    } else {
      // For other file types, provide a fallback
      contentElement.innerHTML = `
        <p>Preview not available for this file type.</p>
        <a href="${signedUrl}" target="_blank">Download file</a>
      `;
    }
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    setAxiosConfig({
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      data: '{}',
    });
    dispatch(fetchConfigs(token));
    dispatch(fetchEntitiesData(['form_fields']));
    loadDocumentTypes();
    loadGrinders();
    loadCountries();
  }, [token]);

  useEffect(() => {
    if (!entities?.form_fields?.entities) {
      return;
    }
    setFormFields(
      entities.form_fields.entities.reduce((o, c) => {
        // eslint-disable-next-line no-param-reassign
        o[c.type] = o[c.type] || [];
        o[c.type].push(c);
        return o;
      }, {})
    );
  }, [entities]);

  return (
    <Box className="documents-queue" margin="2em">
      <HStack gap="10px" justifyContent="space-between" mb={6}>
        <HStack>
          <RightAngleIcon />
          <Heading className="dashboard-section__heading" fontSize="15px">
            Documents Queue
          </Heading>
        </HStack>
        <HStack className="filters">
          <Box>
            <InputGroup size="md" startElement={<FaSearch color="gray.300" />}>
              <Input
                name="po_number"
                width="200px"
                type="text"
                placeholder="PO Number"
                onChange={e => setPoNumberFilter(e.target.value)}
                value={poNumberFilter}
              />
            </InputGroup>
          </Box>
          {/* DOCUMENT TYPE */}
          <Box>
            <MenuRoot closeOnSelect={false}>
              <MenuTrigger as={Button} variant="outline" colorScheme="gray.200" color="gray.500" borderColor="gray.200">
                Document Type
                <FaChevronDown />
              </MenuTrigger>
              <MenuContent>
                <Stack py={4} px={3}>
                  {documentTypes?.map(({ name }) => (
                    <Checkbox
                      key={name}
                      checked={docTypeFilter.includes(name)}
                      onChange={e =>
                        setDocTypeFilter(
                          e.target.checked ? [...docTypeFilter, name] : docTypeFilter.filter(i => i !== name)
                        )
                      }
                    >
                      {name}
                    </Checkbox>
                  ))}
                </Stack>
              </MenuContent>
            </MenuRoot>
          </Box>
          {/* PRODUCT TYPE */}
          <Box>
            <MenuRoot closeOnSelect={false}>
              <MenuTrigger as={Button} variant="outline" colorScheme="gray.200" color="gray.500" borderColor="gray.200">
                Product Type
                <FaChevronDown />
              </MenuTrigger>
              <MenuContent>
                <Stack py={4} px={3}>
                  {formFields?.product_types?.map(({ value }) => (
                    <Checkbox
                      key={value}
                      checked={prodTypeFilter.includes(value)}
                      onChange={e =>
                        setProdTypeFilter(
                          e.target.checked ? [...prodTypeFilter, value] : prodTypeFilter.filter(i => i !== value)
                        )
                      }
                    >
                      {value}
                    </Checkbox>
                  ))}
                </Stack>
              </MenuContent>
            </MenuRoot>
          </Box>
          {/* GRINDER */}
          <Box>
            <MenuRoot closeOnSelect={false}>
              <MenuTrigger as={Button} variant="outline" colorScheme="gray.200" color="gray.500" borderColor="gray.200">
                Grinder
                <FaChevronDown />
              </MenuTrigger>
              <MenuContent>
                <Stack py={4} px={3}>
                  {grinders?.map(({ label, value }) => (
                    <Checkbox
                      key={value}
                      checked={grinderUidFilter.includes(value)}
                      onChange={e =>
                        setGrinderUidFilter(
                          e.target.checked ? [...grinderUidFilter, value] : grinderUidFilter.filter(i => i !== value)
                        )
                      }
                    >
                      {label}
                    </Checkbox>
                  ))}
                </Stack>
              </MenuContent>
            </MenuRoot>
          </Box>
          {/* OTHER */}
          <Box>
            <MenuRoot closeOnSelect={false}>
              <MenuTrigger as={Button} variant="outline" colorScheme="gray.200" color="gray.500" borderColor="gray.200">
                Other
                <FaChevronDown />
              </MenuTrigger>
              <MenuContent>
                <Stack py={4} px={3}>
                  {/* ORIGIN COUNTRY */}
                  <Field label="Origin Country">
                    <MenuRoot closeOnSelect={false}>
                      <MenuTrigger
                        as={Button}
                        variant="outline"
                        colorScheme="gray.200"
                        color="gray.500"
                        borderColor="gray.200"
                      >
                        Select Origin Country
                        <FaChevronDown />
                      </MenuTrigger>
                      <MenuContent>
                        <Stack py={4} px={3}>
                          {countries?.map(({ value, label }) => (
                            <Checkbox
                              key={value}
                              checked={originCountryFilter.includes(value)}
                              onChange={e =>
                                setOriginCountryFilter(
                                  e.target.checked
                                    ? [...originCountryFilter, value]
                                    : originCountryFilter.filter(i => i !== value)
                                )
                              }
                            >
                              {label}
                            </Checkbox>
                          ))}
                        </Stack>
                      </MenuContent>
                    </MenuRoot>
                  </Field>
                  {/* PURCHASING OFFICE */}
                  <Field label="Purchasing Office">
                    <MenuRoot closeOnSelect={false}>
                      <MenuTrigger
                        as={Button}
                        variant="outline"
                        colorScheme="gray.200"
                        color="gray.500"
                        borderColor="gray.200"
                      >
                        Select Purchasing Office
                        <FaChevronDown />
                      </MenuTrigger>
                      <MenuContent>
                        <Stack py={4} px={3}>
                          {formFields?.business_office?.map(({ value }) => (
                            <Checkbox
                              key={value}
                              checked={purchasingOfficeFilter.includes(value)}
                              onChange={e =>
                                setPurchasingOfficeFilter(
                                  e.target.checked
                                    ? [...purchasingOfficeFilter, value]
                                    : purchasingOfficeFilter.filter(i => i !== value)
                                )
                              }
                            >
                              {value}
                            </Checkbox>
                          ))}
                        </Stack>
                      </MenuContent>
                    </MenuRoot>
                  </Field>
                  <Field label="From Email">
                    <Input
                      name="from_email"
                      type="text"
                      onChange={e => setFromEmailAddressFilter(e.target.value)}
                      value={fromEmailAddressFilter}
                    />
                  </Field>
                </Stack>
              </MenuContent>
            </MenuRoot>
          </Box>
          {/* FILTER */}
          <Button colorScheme="blue" width="100px" onClick={loadAttachments}>
            Filter
          </Button>
        </HStack>
      </HStack>
      <HStack alignItems="normal">
        <Box className="facets" fontSize="14px">
          {STATUS_FILTER.map(f => (
            <div
              className={['facet', statusFilter === f.value ? 'active' : ''].join(' ')}
              key={f.label}
              onClick={() => {
                setStatusFilter(f.value);
                setAttachments(null);
              }}
              onKeyPress={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setStatusFilter(f.value);
                  setAttachments(null);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <span>{f.icon(statusFilter === f.value)}</span>
              <span className="label">{f.label}</span>
              <Badge colorScheme="primary">{statusFacet[f.value] || 0}</Badge>
            </div>
          ))}
        </Box>
        <Box className="results">
          {Array.isArray(attachments) && (
            <>
              {attachments.length > 0 ? (
                <Box style={{ width: '100%' }}>
                  <Table.Root variant="simple" style={{ minHeight: '200px' }}>
                    <Table.Header>
                      <Table.Row>
                        <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold">
                          SUBJECT /EMAIL /TYPE
                        </Table.ColumnHeader>
                        <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold">
                          <Box display="flex" alignItems="center" gap="5px">
                            <Text>DOCUMENT NAME</Text> <MdArrowUpward onClick={() => setSortFilter('filename|asc')} />
                            <MdArrowDownward onClick={() => setSortFilter('filename|desc')} />
                          </Box>
                        </Table.ColumnHeader>
                        <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold">
                          <Box display="flex" alignItems="center" gap="5px">
                            <Text>PO NUMBER</Text> <MdArrowUpward onClick={() => setSortFilter('po_number|asc')} />
                            <MdArrowDownward onClick={() => setSortFilter('po_number|desc')} />
                          </Box>
                        </Table.ColumnHeader>
                        <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold">
                          PO LOOKUP FIELD
                        </Table.ColumnHeader>
                        <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold">
                          <Box display="flex" alignItems="center" gap="5px">
                            <Text>RECEIVED DATE</Text> <MdArrowUpward onClick={() => setSortFilter('created|asc')} />
                            <MdArrowDownward onClick={() => setSortFilter('created|desc')} />
                          </Box>
                        </Table.ColumnHeader>
                        <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold">
                          STATUS
                        </Table.ColumnHeader>
                        <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold">
                          ACTION
                        </Table.ColumnHeader>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {attachments &&
                        attachments.map(a => (
                          <Table.Row key={a.id} borderBottom="1px solid #e4edf3">
                            <Table.Cell>
                              <b>{a.email?.subject}</b>
                              <br />
                              {a.email?.from_email?.value?.[0].address} <Badge colorScheme="purple">{a.doc_type}</Badge>
                            </Table.Cell>
                            <Table.Cell>{a.filename}</Table.Cell>
                            <Table.Cell>
                              <a
                                style={{ textDecorationStyle: 'solid', textDecorationLine: 'underline' }}
                                href={`/order/search?${a.po_lookup_field}=${a.po_number}&po_lookup_field=${a.po_lookup_field}&auto_open_po_card_purchase_order_id=${a.purchase_order_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {a.po_number}
                              </a>
                            </Table.Cell>
                            <Table.Cell>{a.po_lookup_field?.replaceAll('_', ' ')}</Table.Cell>
                            <Table.Cell>{moment(a.created).format('MM-DD-YYYY h:mm a')}</Table.Cell>
                            <Table.Cell>
                              <Badge colorScheme={EMAIL_ATTACHMENTS_STATUS_COLOR[a.status]}>
                                {a.is_draft ? 'Draft/' : ''}
                                {a.status}
                              </Badge>
                            </Table.Cell>
                            <Table.Cell>
                              <Button
                                loading={a.isLoading}
                                onClick={() => openAttachmentView(a)}
                                variant="outline"
                                gap="0"
                                padding="3px 8px"
                                height="32px"
                                minWidth="32px"
                              >
                                <FaEye />
                                &nbsp;
                              </Button>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                    </Table.Body>
                  </Table.Root>
                  {attachments && (
                    <div className="custom_pagination">
                      <ReactPaginate
                        forcePage={currentPage}
                        pageCount={pagesCount}
                        pageRangeDisplayed={pagesCount < 8 ? pagesCount : 8}
                        marginPagesDisplayed={0}
                        onPageChange={page => setCurrentPage(page?.selected || 0)}
                        containerClassName="pagination"
                        subContainerClassName="pages pagination"
                        activeClassName="active"
                        previousLabel={<i className="fa fa-angle-left" />}
                        nextLabel={<i className="fa fa-angle-right" />}
                      />
                      <div className="pagination-stats">
                        {currentPage + 1} / {pagesCount}
                      </div>
                    </div>
                  )}
                </Box>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 150 150" fill="none">
                    <path
                      d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
                      fill="url(#paint0_linear_8992_68753)"
                    />
                    <path
                      d="M120 150H30V53C34.242 52.9952 38.3089 51.308 41.3084 48.3085C44.308 45.3089 45.9952 41.242 46 37H104C103.996 39.1014 104.408 41.1828 105.213 43.1238C106.018 45.0648 107.2 46.8268 108.691 48.308C110.172 49.7991 111.934 50.9816 113.875 51.787C115.817 52.5924 117.898 53.0047 120 53V150Z"
                      fill="white"
                    />
                    <path
                      d="M75 102C88.2548 102 99 91.2548 99 78C99 64.7452 88.2548 54 75 54C61.7452 54 51 64.7452 51 78C51 91.2548 61.7452 102 75 102Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M83.4853 89.3138L75 80.8286L66.5147 89.3138L63.6863 86.4854L72.1716 78.0001L63.6863 69.5148L66.5147 66.6864L75 75.1717L83.4853 66.6864L86.3137 69.5148L77.8284 78.0001L86.3137 86.4854L83.4853 89.3138Z"
                      fill="white"
                    />
                    <path
                      d="M88 108H62C60.3431 108 59 109.343 59 111C59 112.657 60.3431 114 62 114H88C89.6569 114 91 112.657 91 111C91 109.343 89.6569 108 88 108Z"
                      fill="#DFEAFB"
                    />
                    <path
                      d="M97 120H53C51.3431 120 50 121.343 50 123C50 124.657 51.3431 126 53 126H97C98.6569 126 100 124.657 100 123C100 121.343 98.6569 120 97 120Z"
                      fill="#DFEAFB"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_8992_68753"
                        x1="75"
                        y1="0"
                        x2="75"
                        y2="150"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#E3ECFA" />
                        <stop offset="1" stopColor="#DAE7FF" />
                      </linearGradient>
                    </defs>
                  </svg>
                  <div>
                    <br />
                    <b>No Data!</b>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    The {statusFilter} is empty; there is no
                    <br />
                    data available.
                  </div>
                </div>
              )}
            </>
          )}
        </Box>
      </HStack>
      {/* VIEW MODAL */}
      <DialogRoot className="documents-modal" open={isViewOpen} size="full" onClose={onViewClose} centered>
        <DialogContent>
          <form className="documents-queue-view-modal" onSubmit={handleSubmit}>
            <Box className="header custom-header">
              <IconButton
                onClick={onViewClose}
                height="32px"
                width="32px"
                marginTop="20px"
                marginLeft="20px"
                variant="ghost"
              >
                <MdArrowBack size="22" />
              </IconButton>
              <DialogCloseTrigger asChild className="close-btn" onClick={onViewClose} />
            </Box>
            <Flex className="main" marginTop="80px">
              <Box className="attachment-pane" order={3}>
                {selectedAttachment && (
                  <>
                    <div className="left-top">
                      <Flex justifyContent="space-between" mb={2}>
                        <div>
                          <span className="email-subject">{selectedAttachment?.email?.subject}</span>
                          <Badge ml={2} colorScheme={EMAIL_ATTACHMENTS_STATUS_COLOR[selectedAttachment?.status]}>
                            {selectedAttachment?.is_draft ? 'Draft/' : ''}
                            {selectedAttachment?.status}
                          </Badge>
                        </div>
                        <span className="email-attachment-created">
                          {moment(selectedAttachment?.created).format('MM-DD-YYYY h:mm a')}
                        </span>
                      </Flex>
                      <Flex alignItems="center" className="box-border">
                        <Avatar name={selectedAttachment?.email?.from_email?.value[0]?.name} />
                        <p>
                          <b>{selectedAttachment?.email?.from_email?.value[0]?.name}</b>
                          {` <${selectedAttachment?.email?.from_email?.value[0]?.address}>`}
                        </p>
                      </Flex>
                      <p>
                        {selectedAttachment?.email?.text?.split('\n').map(line => (
                          <React.Fragment key={line}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </p>
                      <HStack flexWrap="wrap">
                        <Flex alignItems="center" style={{ border: '1px solid #E2E2E2' }}>
                          {/* preview */}
                          <Button
                            className="pdf-attachment__filename"
                            onClick={() => openFileViewerInNewWindow(selectedAttachment)}
                          >
                            {selectedAttachment.fileType === 'pdf' && <AiOutlineFilePdf color="red" fontSize="24px" />}
                            {selectedAttachment.fileType === 'json' && <BsFiletypeJson fontSize="24px" />}
                            {selectedAttachment?.filename}
                          </Button>
                          {/* preview & download options */}
                          <MenuRoot>
                            <MenuTrigger as={Button} className="pdf-attachment__btn" ml={2}>
                              <MdDownload />
                            </MenuTrigger>
                            <MenuContent>
                              <MenuItem onClick={() => openFileViewerInNewWindow(selectedAttachment)}>Preview</MenuItem>
                              <MenuItem
                                onClick={() => {
                                  if (selectedAttachment?.signedUrl) {
                                    const newWindow = window.open(
                                      selectedAttachment.signedUrl,
                                      '_blank', // Open in a new tab
                                      'noopener,noreferrer,width=800,height=600' // Specify window size
                                    );
                                    if (!newWindow) {
                                      // eslint-disable-next-line no-alert
                                      alert('Pop-up blocked! Please allow pop-ups for this website.');
                                    }
                                  } else {
                                    // eslint-disable-next-line no-console
                                    console.error('No file URL available.');
                                  }
                                }}
                              >
                                Download
                              </MenuItem>
                            </MenuContent>
                          </MenuRoot>
                        </Flex>
                      </HStack>
                    </div>
                    {selectedAttachment.fileType !== 'json' && (
                      <div className="left-bottom">
                        <Flex justifyContent="space-between" alignItems="center">
                          <div>Preview</div>
                          <div>
                            <Box mr={2}>Zoom</Box>
                            <input
                              type="range"
                              min="100"
                              max="400"
                              step="10"
                              value={filePreviewZoom}
                              onChange={e => {
                                setFilePreviewZoom(e.target.value);
                                // eslint-disable-next-line no-undef
                                $('canvas').css('zoom', `${e.target.value}%`);
                              }}
                            />
                          </div>
                        </Flex>
                        {selectedAttachment.render === 'react-file-viewer' && (
                          <FileViewer
                            fileType={selectedAttachment.fileType}
                            filePath={selectedAttachment.signedUrl}
                            // eslint-disable-next-line no-console
                            onError={e => console.log('error', e)}
                          />
                        )}

                        <div ref={xlsxViewerEle}>&nbsp;</div>
                      </div>
                    )}
                  </>
                )}
              </Box>
              {/* Resizer */}
              <Box
                order={2}
                cursor="col-resize"
                bg="gray.400"
                width="30px"
                height="100vh"
                backgroundColor="transparent"
                alignItems="center"
                justifyContent="center"
                onMouseDown={handleMouseDown}
                zIndex="1"
              >
                &nbsp;
              </Box>
              <Box className="details-pane" width={`${leftWidth}%`} order={1}>
                <Stack className="top" gap={5}>
                  <Select
                    id="doc_type"
                    invalid={errors.doc_type && touched.doc_type}
                    errorText={errors.doc_type}
                    label="Document type*"
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: true,
                      removeSelected: true,
                      isMulti: false,
                      value: { value: values.doc_type, label: values.doc_type },
                      onChange: op => setFieldValue('doc_type', op.value),
                      options: documentTypes.map(({ name }) => ({ value: name, label: name })) || [],
                    }}
                  />

                  <Select
                    id="po_lookup_field"
                    invalid={errors.po_lookup_field && touched.po_lookup_field}
                    errorText={errors.po_lookup_field}
                    label="PO Lookup Field*"
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: true,
                      removeSelected: true,
                      isMulti: false,
                      value: {
                        value: values.po_lookup_field,
                        label: PO_FROM[values.po_lookup_field]?.replaceAll('_', ' '),
                      },
                      onChange: op => {
                        setFieldValue('po_lookup_field', op.value);
                        setFieldValue('po_number', '');
                      },
                      options: Object.keys(PO_FROM).map(key => ({
                        value: key,
                        label: PO_FROM[key]?.replaceAll('_', ' '),
                      })),
                    }}
                  />

                  <Field id="po_number" invalid={errors.po_number && touched.po_number} errorText={errors.po_number}>
                    <Flex justifyContent="space-between" alignItems="center">
                      {PO_FROM.internal_po_number === values.po_lookup_field && (
                        <label style={{ fontSize: '14px', fontWeight: 'bold' }}>{getInternalPoLabel(configs)}*</label>
                      )}
                      {PO_FROM.packer_so_number === values.po_lookup_field && (
                        <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Packer PO #*</label>
                      )}
                      {PO_FROM.grinder_po_number === values.po_lookup_field && (
                        <label style={{ fontSize: '14px', fontWeight: 'bold' }}>Grinder PO #*</label>
                      )}
                      <Box>
                        {values.po_lookup_field && values.po_number && (
                          <div style={{ margin: '2px 3px', alignItems: 'right' }}>
                            <a
                              href={`/order/search?${values.po_lookup_field}=${values.po_number}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              View Detail
                            </a>
                          </div>
                        )}
                      </Box>
                    </Flex>
                    <Input
                      name="po_number"
                      type="text"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.po_number}
                    />
                  </Field>
                  {values?.doc_specific_checks && Object.keys(values.doc_specific_checks).length > 0 && (
                    <Box width="100%">
                      <Table.Root
                        variant="simple"
                        className="details-table"
                        style={{ minHeight: '100px' }}
                        width="100%"
                        border="1px solid #ebedef"
                      >
                        <Table.Header>
                          <Table.Row>
                            <Table.ColumnHeader
                              backgroundColor="#f7fafc"
                              fontWeight="semibold"
                              borderBottom="1px solid #ebedef"
                            >
                              ITEM
                            </Table.ColumnHeader>
                            <Table.ColumnHeader
                              backgroundColor="#f7fafc"
                              fontWeight="semibold"
                              borderBottom="1px solid #ebedef"
                            >
                              PO QTY({values.purchase_order.buy_unit_of_measure.name})
                            </Table.ColumnHeader>
                            <Table.ColumnHeader
                              backgroundColor="#f7fafc"
                              fontWeight="semibold"
                              borderBottom="1px solid #ebedef"
                            >
                              INVOICE QTY({values.purchase_order.buy_unit_of_measure.name})
                            </Table.ColumnHeader>
                            <Table.ColumnHeader
                              backgroundColor="#f7fafc"
                              fontWeight="semibold"
                              borderBottom="1px solid #ebedef"
                            >
                              RECEIVED QTY({values.purchase_order.buy_unit_of_measure.name})
                            </Table.ColumnHeader>
                            <Table.ColumnHeader
                              backgroundColor="#f7fafc"
                              fontWeight="semibold"
                              borderBottom="1px solid #ebedef"
                            >
                              PO PRICE({values.purchase_order.buy_currency})
                            </Table.ColumnHeader>
                            <Table.ColumnHeader
                              backgroundColor="#f7fafc"
                              fontWeight="semibold"
                              borderBottom="1px solid #ebedef"
                            >
                              INVOICE PRICE({values.purchase_order.buy_currency})
                            </Table.ColumnHeader>
                            <Table.ColumnHeader
                              backgroundColor="#f7fafc"
                              fontWeight="semibold"
                              borderBottom="1px solid #ebedef"
                            >
                              3-WAY MATCH
                            </Table.ColumnHeader>
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {Object.entries(values.doc_specific_checks).map(([key, value]) => (
                            <Table.Row key={key}>
                              <Table.Cell backgroundColor="#fff" borderBottom="1px solid #ebedef">
                                {value.item ? value.item : '-'}
                              </Table.Cell>
                              <Table.Cell backgroundColor="#fff" borderBottom="1px solid #ebedef">
                                {formatNumber(value.po_qty)}
                              </Table.Cell>
                              <Table.Cell backgroundColor="#fff" borderBottom="1px solid #ebedef">
                                {formatNumber(value.inv_qty)}
                              </Table.Cell>
                              <Table.Cell backgroundColor="#fff" borderBottom="1px solid #ebedef">
                                {formatNumber(value.recv_qty)}
                              </Table.Cell>
                              <Table.Cell backgroundColor="#fff" borderBottom="1px solid #ebedef">
                                {value.po_unit_price ? value.po_unit_price : '-'}
                              </Table.Cell>
                              <Table.Cell backgroundColor="#fff" borderBottom="1px solid #ebedef">
                                {value.inv_unit_price ? value.inv_unit_price : '-'}
                              </Table.Cell>
                              <Table.Cell backgroundColor="#fff" borderBottom="1px solid #ebedef">
                                {value.po_qty === value.inv_qty && value.inv_qty === value.recv_qty ? 'YES' : 'NO'}
                              </Table.Cell>
                            </Table.Row>
                          ))}
                        </Table.Body>
                      </Table.Root>
                    </Box>
                  )}

                  <Select
                    id="status"
                    invalid={errors.status && touched.status}
                    errorText={errors.status}
                    label="Change Status"
                    {...{
                      isClearable: true,
                      isSearchable: true,
                      closeMenuOnSelect: true,
                      removeSelected: true,
                      isMulti: false,
                      value: {
                        value: values.status,
                        label: STATUS_OPTIONS.find(s => s.value === values.status)?.label,
                      },
                      onChange: op => setFieldValue('status', op.value),
                      options: STATUS_OPTIONS.map(({ label, value }) => ({ value, label })),
                    }}
                  />
                  {values.doc_specific_checks && values.status === 'completed' && (
                    <Text fontSize="sm" width="100%" textAlign="left" fontStyle="italic">
                      PO&apos;s packer invoice number, date are populated, ready for payment checked.
                    </Text>
                  )}

                  {![EMAIL_ATTACHMENTS_STATUS.pending].includes(values.status) && (
                    <Field id="notes" invalid={errors.notes && touched.notes} errorText={errors.notes}>
                      <Textarea
                        name="notes"
                        onChange={handleChange}
                        placeholder="Notes"
                        onBlur={handleBlur}
                        value={values.notes}
                      />
                    </Field>
                  )}
                </Stack>
                <HStack className="bottom" alignItems="flex-end">
                  <Button
                    onClick={() =>
                      window.open(
                        `mailto:${selectedAttachment?.email?.from_email?.value?.[0]?.address}?subject=${selectedAttachment?.doc_type} (PO# ${selectedAttachment?.po_number})`,
                        '_blank'
                      )
                    }
                    backgroundColor="#edf2f7"
                    color="#333"
                    mr={3}
                    disabled={!isValid}
                  >
                    Send Email
                  </Button>
                  <HStack alignItems="flex-end">
                    {isLoading && (
                      <Box mr={3}>
                        <Spinner />
                      </Box>
                    )}
                    <Button
                      type="submit"
                      onClick={() => setFieldValue('is_draft', true)}
                      colorScheme="blue"
                      variant="outline"
                      mr={3}
                      disabled={!isValid}
                    >
                      Save Draft
                    </Button>
                    <VStack gap={1}>
                      <Button
                        type="submit"
                        onClick={() => setFieldValue('is_draft', false)}
                        colorScheme="blue"
                        disabled={!isValid}
                      >
                        Submit
                      </Button>
                    </VStack>
                  </HStack>
                </HStack>
              </Box>
            </Flex>
          </form>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};
