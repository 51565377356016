import { connect } from 'react-redux';
import { deleteReport, fetchReportConfig, runReport } from '../../actions/actions_self_service_reports';
import SelfServiceReportConfig from '../../components/selfServiceReports/selfServiceReportConfig/selfServiceReportConfig';
import withRouter from '@/components/utilities/withRouter';

const mapStateToProps = state => {
  return {
    user: state.user,
    selfServiceReportConfig: state.selfServiceReportConfig,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchReportConfig: (token, reportId) => dispatch(fetchReportConfig(token, reportId)),
    runReport: (token, params, reportId) => dispatch(runReport(token, params, reportId)),
    deleteReport: (token, reportId) => dispatch(deleteReport(token, reportId)),
  };
};

const reportConfig = withRouter(connect(mapStateToProps, mapDispatchToProps)(SelfServiceReportConfig));
export default reportConfig;
