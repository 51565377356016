import { Box, Button, HStack, Input, Stack, Heading, IconButton } from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Constants from '../../../Constants';
import { selectCurrentUser } from '../../../reducers/reducer_user';
import RightAngleIcon from '../../../components/core/RightAngleIcon';
import './documents-queue-tester.scss';
import { Tooltip } from '@/components/ui/tooltip';
import { Field } from '@/components/ui/field';
import { IoInformationCircleOutline } from 'react-icons/io5';

export default () => {
  const { token } = useSelector(selectCurrentUser);
  const [axiosConfig, setAxiosConfig] = useState();
  const [testResult, setTestResult] = useState();
  const [isLoading, setLoading] = useState();

  useEffect(() => {
    if (!token) {
      return;
    }
    setAxiosConfig({
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      data: '{}',
    });
  }, [token]);

  const testEmailAttachment = async emailAttachment => {
    setLoading(true);
    try {
      const data = new FormData();
      data.append('email_attachment', JSON.stringify(emailAttachment));
      if (emailAttachment.file) {
        data.append('file', emailAttachment.file, emailAttachment?.file?.filename || emailAttachment?.file?.name);
      }
      const res = await axios.post(`${Constants.URL}emails/attachments-test`, data, axiosConfig);
      setTestResult(res.data);
    } finally {
      setLoading(false);
    }
  };

  const { errors, handleChange, handleBlur, setFieldValue, handleSubmit, values, touched, isValid } = useFormik({
    initialValues: {
      email: {
        subject: '',
        text: '',
        from_email: { value: [{ address: '', name: '' }] },
        to: { value: [{ address: '', name: '' }] },
      },
    },
    validationSchema: Yup.object().shape({
      email: Yup.object().shape({
        subject: Yup.string().nullable(),
        text: Yup.string().nullable(),
        from_email: Yup.object().shape({
          value: Yup.array().of(
            Yup.object().shape({
              address: Yup.string(),
              name: Yup.string(),
            })
          ),
        }),
        to: Yup.object().shape({
          value: Yup.array().of(
            Yup.object().shape({
              address: Yup.string(),
              name: Yup.string(),
            })
          ),
        }),
      }),
      file: Yup.mixed().nullable(),
    }),
    onSubmit: testEmailAttachment,
  });

  console.log('testResult ===>', testResult);

  return (
    <Box className="documents-tester" margin="2em">
      <HStack gap="10px" justifyContent="space-between" mb={6}>
        <HStack>
          <RightAngleIcon />
          <Heading className="dashboard-section__heading" fontSize="15px">
            Tester
          </Heading>
        </HStack>
      </HStack>
      <HStack align="start">
        <form onSubmit={handleSubmit} className="tester-form">
          <Stack gap="10">
            <Field
              id="email.subject"
              invalid={errors['email.subject'] && touched['email.subject']}
              label={
                <>
                  Email subject&nbsp;
                  <Tooltip
                    content="Enter the subject line of the email to test document upload"
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
              errorText={errors['email.subject']}
            >
              <Input
                name="email.subject"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values['email.subject']}
              />
            </Field>
            <Field
              id="email.text"
              invalid={errors['email.text'] && touched['email.text']}
              label={
                <>
                  Email Body&nbsp;
                  <Tooltip
                    content="Provide the content of the email for testing"
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
              errorText={errors['email.text']}
            >
              <Input
                name="email.text"
                type="textarea"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values['email.text']}
              />
            </Field>
            <Field
              id="email.from_email.value[0].address"
              invalid={errors['email.from_email.value[0].address'] && touched['email.from_email.value[0].address']}
              label={
                <>
                  Email Address From&nbsp;
                  <Tooltip
                    content="Specify the email address from which the test email is sent"
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
              errorText={errors['email.from_email.value[0].address']}
            >
              <Input
                name="email.from_email.value[0].address"
                type="textarea"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values['email.from_email.value[0].address']}
              />
            </Field>
            <Field
              id="email.to.value[0].address"
              invalid={errors['email.to.value[0].address'] && touched['email.to.value[0].address']}
              label={
                <>
                  Email Address To&nbsp;
                  <Tooltip
                    content="Specify the email address to which the test email is sent"
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
              errorText={errors['email.to.value[0].address']}
            >
              <Input
                name="email.to.value[0].address"
                type="textarea"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values['email.to.value[0].address']}
              />
            </Field>
            <Field
              id="file"
              invalid={errors.file && touched.file}
              label={
                <>
                  Email Attachment&nbsp;
                  <Tooltip
                    content="Attach a document to the email for testing the upload process"
                    positioning={{ placement: 'right-end' }}
                  >
                    <IconButton
                      width="14px"
                      height="14px"
                      padding="0"
                      minW="auto"
                      borderRadius="50%"
                      color="#878787"
                      variant="unstyled"
                    >
                      <IoInformationCircleOutline size="14px" />
                    </IconButton>
                  </Tooltip>
                </>
              }
              errorText={errors.file}
            >
              <Input
                name="file"
                type="file"
                onChange={e => {
                  setFieldValue('file', e.target.files[0]);
                }}
                onBlur={handleBlur}
              />
            </Field>
          </Stack>
          <HStack justifyContent="end">
            <Button type="submit" colorScheme="actionPrimary" mt="3em" disabled={!isValid} loading={isLoading}>
              Test
            </Button>
          </HStack>
        </form>
        <Box className="tester-results">
          {testResult ? (
            <div style={{ width: '100%' }} dangerouslySetInnerHTML={{ __html: testResult }} />
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div>
                <br />
                <b>No Test Results Available!</b>
              </div>
              <div style={{ textAlign: 'center' }}>Please provide the necessary information to view your results.</div>
            </div>
          )}
        </Box>
      </HStack>
    </Box>
  );
};
