import { Badge, Box, Button, HStack, Input, Stack, Heading, Table, Text, VStack, IconButton } from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import Constants from '../../../Constants';
import ConditionBuilder from '../../../components/ConditionBuilder/ConditionBuilder';
import Select from '../../../components/basic/SelectField';
import { selectCurrentUser } from '../../../reducers/reducer_user';
import RightAngleIcon from '../../../components/core/RightAngleIcon';
import './document-types.scss';
import { Tooltip } from '@/components/ui/tooltip';
import { IoInformationCircleOutline } from 'react-icons/io5';
import {
  DialogBody,
  DialogCloseTrigger,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogRoot,
} from '@/components/ui/dialog';
import { MenuContent, MenuRoot, MenuTrigger } from '@/components/ui/menu';
import { MdAdd, MdDelete, MdEdit } from 'react-icons/md';
import { Checkbox } from '@/components/ui/checkbox';
import { Field } from '@/components/ui/field';

const stringOps = [
  { label: 'Equal', value: "%var == '%val'", inputRequired: true },
  { label: 'Not equal', value: "%var != '%val'", inputRequired: true },
  { label: 'Equal (case insensitive)', value: "%var.lower() == '%val'.lower()", inputRequired: true },
  { label: 'Not equal (case insensitive)', value: "%var.lower() != '%val'.lower()", inputRequired: true },
  { label: 'Contain', value: "'%val' in %var", inputRequired: true },
  { label: 'Not contain', value: "not '%val' in %var", inputRequired: true },
  { label: 'Contain (case insensitive)', value: "bool(re.search('%val', %var, re.IGNORECASE))", inputRequired: true },
  {
    label: 'Not contain (case insensitive)',
    value: "not bool(re.search('%val', %var, re.IGNORECASE))",
    inputRequired: true,
  },
  { label: 'Match', value: "re.match('%val', %var)", inputRequired: true },
  { label: 'Not Match', value: "not re.match('%val', %var)", inputRequired: true },
];
const arrayOfstringsOps = [
  { label: 'Contain', value: "'%val' in %var", inputRequired: true },
  { label: 'Not contain', value: "not '%val' in %var", inputRequired: true },
];
const emailDocMatchConditions = [
  {
    name: 'email_attachment.email.subject',
    label: 'Email Subject',
    ops: [...stringOps],
  },
  {
    name: 'email_attachment.email.text',
    label: 'Email Body',
    ops: [...stringOps],
  },
  {
    name: '[i["address"] for i in email_attachment.email.from_email["value"]]',
    label: 'Email Address From',
    ops: [...arrayOfstringsOps],
  },
  {
    name: '[i["address"] for i in email_attachment.email.to["value"]]',
    label: 'Email Address To',
    ops: [...arrayOfstringsOps],
  },
  {
    name: 'email_attachment.filename',
    label: 'Email Attachment File Name',
    ops: [...stringOps],
  },
  {
    name: 'email_attachment.file_text',
    label: 'Email Attachment File Text',
    ops: [...stringOps],
  },
];

const extractor_fields = ['po_lookup_field', 'po_number'];

const formatOptions = v => ({ label: v.replaceAll('_', ' '), value: v });

export default () => {
  const [preventClosingModalOnSubmit, setPreventClosingModalOnSubmit] = useState(false);
  const { token } = useSelector(selectCurrentUser);
  const [axiosConfig, setAxiosConfig] = useState();
  const [documentTypes, setDocumentTypes] = useState([]);
  const [isDevMode] = useState(new URLSearchParams(window.location.search).get('mode') === 'dev');
  const [showFormModal, setShowFormModal] = useState(false);

  const loadDocumentTypes = async () =>
    axios.get(`${Constants.URL}document-types`, axiosConfig).then(response => setDocumentTypes(response.data));

  const saveDocumentType = async data => {
    (!data.created
      ? axios.post(`${Constants.URL}document-types`, data, axiosConfig)
      : axios.patch(`${Constants.URL}document-types/${data.id}`, data, axiosConfig)
    ).then(loadDocumentTypes);
  };

  const closeFormModal = () => setShowFormModal(false);

  const {
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    setValues,
    resetForm,
    values,
    touched,
    isValid,
  } = useFormik({
    initialValues: {},
    validationSchema: Yup.object().shape({
      name: Yup.string().nullable().required('Required'),
      email_doc_match_rule: Yup.object().nullable().required('Required'),
      email_doc_fields_extractors_rule: Yup.object().nullable(),
      email_doc_auto_upload: Yup.bool().nullable(),
    }),
    onSubmit: data => {
      saveDocumentType(data);
      if (!preventClosingModalOnSubmit) {
        closeFormModal();
      }
    },
  });

  const removeDocumentType = async documentTypeId => {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure you want to delete this item?')) {
      axios
        .delete(`${Constants.URL}document-types/${documentTypeId || values.id}`, axiosConfig)
        .then(loadDocumentTypes);
      if (!documentTypeId) {
        closeFormModal();
      }
    }
  };

  const openFormModal = data => {
    resetForm();
    setValues(data);
    setShowFormModal(true);
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    setAxiosConfig({
      headers: {
        Authorization: `Token ${token}`,
        'Content-Type': 'application/json',
      },
      data: '{}',
    });
    loadDocumentTypes();
  }, [token]);

  return (
    <Box margin="2em" className="document-types">
      <HStack gap="10px" justifyContent="space-between" mb={6}>
        <HStack>
          <RightAngleIcon />
          <Heading className="dashboard-section__heading" fontSize="15px">
            Document Types
          </Heading>
        </HStack>
        <VStack alignItems="end">
          <Button colorScheme="actionPrimary" mb="1em" onClick={() => openFormModal({})}>
            Add New
          </Button>
        </VStack>
      </HStack>
      <Box className="table-container">
        <Table.Root variant="simple">
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold" borderBottom="1px solid #ebedef">
                Name
              </Table.ColumnHeader>
              <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold" borderBottom="1px solid #ebedef">
                Auto upload
              </Table.ColumnHeader>
              <Table.ColumnHeader backgroundColor="#f7fafc" fontWeight="semibold" borderBottom="1px solid #ebedef">
                Action
              </Table.ColumnHeader>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {documentTypes.map(item => (
              <Table.Row key={item.id}>
                <Table.Cell width="80%" backgroundColor="#fff" borderBottom="1px solid #ebedef">
                  {item.name}
                </Table.Cell>
                <Table.Cell width="150px" backgroundColor="#fff" borderBottom="1px solid #ebedef">
                  {item.email_doc_auto_upload ? 'Yes' : 'No'}
                </Table.Cell>
                <Table.Cell width="100px" backgroundColor="#fff" borderBottom="1px solid #ebedef" className="actions">
                  <Button fontSize="16px" onClick={() => openFormModal(item)} variant="outline">
                    <MdEdit />
                  </Button>
                  <Button fontSize="16px" onClick={() => removeDocumentType(item.id)} variant="outline">
                    <MdDelete />
                  </Button>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>
      </Box>
      <DialogRoot size="lg" open={showFormModal} onClose={closeFormModal}>
        <DialogContent minHeight="200px" className="document-types-modal-content">
          <form onSubmit={handleSubmit}>
            <DialogHeader>Document Type</DialogHeader>
            <DialogCloseTrigger asChild onClick={closeFormModal} />
            <DialogBody>
              <Stack gap="20px">
                <Field
                  id="name"
                  invalid={errors.name && touched.name}
                  label={
                    <>
                      Name&nbsp;
                      <Tooltip
                        content="Enter the rules or guidelines for different document types"
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.name}
                >
                  <Input name="name" type="text" onChange={handleChange} onBlur={handleBlur} value={values.name} />
                </Field>
                <Field
                  className="email-doc-builder"
                  id="email_doc_match_rule"
                  invalid={errors.email_doc_match_rule && touched.email_doc_match_rule}
                  label={
                    <>
                      Email document match rule&nbsp;
                      <Tooltip
                        content="Define multiple rules to categorize and automatically upload documents into the corresponding PO."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                      *
                    </>
                  }
                  errorText={errors.email_doc_match_rule}
                >
                  <ConditionBuilder
                    condition={values.email_doc_match_rule}
                    variables={emailDocMatchConditions}
                    onUpdateCondition={con => {
                      setFieldValue('email_doc_match_rule', con);
                    }}
                  />
                </Field>
                <Field
                  id="email_doc_auto_upload"
                  invalid={errors.email_doc_auto_upload && touched.email_doc_auto_upload}
                  label={
                    <>
                      Auto upload&nbsp;
                      <Tooltip
                        content="Specify whether to automatically upload documents from emails into the PO."
                        positioning={{ placement: 'right-end' }}
                      >
                        <IconButton
                          width="14px"
                          height="14px"
                          padding="0"
                          minW="auto"
                          borderRadius="50%"
                          color="#878787"
                          variant="unstyled"
                        >
                          <IoInformationCircleOutline size="14px" />
                        </IconButton>
                      </Tooltip>
                    </>
                  }
                  errorText={errors.email_doc_auto_upload}
                >
                  <Checkbox
                    name="email_doc_auto_upload"
                    marginRight="auto"
                    checked={values.email_doc_auto_upload}
                    onChange={() => setFieldValue('email_doc_auto_upload', !values.email_doc_auto_upload)}
                    onBlur={handleBlur}
                  >
                    Enable auto upload
                  </Checkbox>
                </Field>
                {isDevMode && (
                  <Box borderWidth="1px" borderRadius="lg" overflow="hidden" padding="1em" minHeight="400px">
                    <Field
                      id="email_doc_fields_extractors_rule"
                      invalid={errors.email_doc_fields_extractors_rule && touched.email_doc_fields_extractors_rule}
                      label={
                        <>
                          Email document fields extractors rule
                          <MenuRoot closeOnSelect={false}>
                            <MenuTrigger as={Button} variant="link" color="primary">
                              Guide
                            </MenuTrigger>
                            <MenuContent className="guide-box">
                              <p>
                                <b>Match</b>
                              </p>
                              <ul>
                                <li>Get patterns from the subject, body and document text.</li>
                                <li>
                                  parentheses &quot;()&quot; are required to indicate the part of the pattern that needs
                                  to be extracted
                                  <br />
                                  <p>Examples:</p>
                                  <p>
                                    &quot;.*(F\\d{5}).*&quot;: extract the pattern that is wrapped by parentheses (A
                                    number of 5 digits that has the prefix &apos;F&apos;), no matter the text that is
                                    before or after the parentheses pattern.
                                  </p>
                                </li>
                              </ul>
                              <p>
                                <b>Eval</b>
                              </p>
                              <ul>
                                <li>Get a value from a python expression, allowing access to all email content.</li>
                                <li>
                                  <p>Examples:</p>
                                  <p>&quot;&quot;internal_po_number&quot;&quot;: return a constant</p>
                                </li>
                              </ul>
                            </MenuContent>
                          </MenuRoot>
                        </>
                      }
                      errorText={errors.email_doc_fields_extractors_rule}
                    >
                      <Select
                        {...{
                          isClearable: true,
                          isSearchable: true,
                          closeMenuOnSelect: false,
                          removeSelected: true,
                          isMulti: true,
                          name: 'email_doc_fields_extractors_rule',
                          value: Object.keys(values.email_doc_fields_extractors_rule || []).map(formatOptions),
                          onChange: events => {
                            const val = events
                              .map(e => e.value)
                              .reduce((o, c) => {
                                // eslint-disable-next-line no-param-reassign
                                o[c] = values.email_doc_fields_extractors_rule?.[c] || [];
                                return o;
                              }, {});
                            setFieldValue('email_doc_fields_extractors_rule', Object.keys(val).length ? val : null);
                          },
                          options: (extractor_fields || []).map(formatOptions),
                        }}
                      />
                    </Field>
                    {Object.keys(values.email_doc_fields_extractors_rule || []).map(fe => (
                      <Field
                        id={fe}
                        key={fe}
                        my="1em"
                        label={
                          <>
                            {fe}&nbsp;
                            <Button
                              className="add-expression"
                              size="sm"
                              colorScheme="actionPrimary"
                              onClick={() => {
                                const fieldsCopy = { ...values.email_doc_fields_extractors_rule };

                                // confirm({
                                //   title: 'Select an expression type',
                                //   content:
                                //     'match: Extract patterns from the email content. eval: Evaluate an expression.',
                                //   zIndex: 9000000,
                                //   okText: 'eval',
                                //   okType: 'default',
                                //   cancelText: 'match',
                                //   onOk() {
                                //     fieldsCopy[fe].push({ type: 'eval', value: '', placeholder: '"literal"' });
                                //     setFieldValue('email_doc_fields_extractors_rule', fieldsCopy);
                                //   },
                                //   onCancel() {
                                //     fieldsCopy[fe].push({ type: 'match', value: '', placeholder: '.*(Fd{5}).*' });
                                //     setFieldValue('email_doc_fields_extractors_rule', fieldsCopy);
                                //   },
                                // });

                                if (window.confirm('Select an expression type: match (Click OK) or eval (Click Cancel)')) {
                                  fieldsCopy[fe].push({ type: 'eval', value: '', placeholder: '"literal"' });
                                } else {
                                  fieldsCopy[fe].push({ type: 'match', value: '', placeholder: '.*(Fd{5}).*' });
                                }

                                setFieldValue('email_doc_fields_extractors_rule', fieldsCopy);
                              }}
                            >
                              <MdAdd />
                              Add New Expression
                            </Button>
                          </>
                        }
                      >
                        <HStack flexWrap="wrap">
                          {(values.email_doc_fields_extractors_rule[fe] || []).map((expression, i) => (
                            <VStack key={i} alignItems="flex-start" marginInlineStart="0 !important">
                              <HStack>
                                <Input
                                  margin="4px"
                                  width="150px"
                                  name={`fe-${i}`}
                                  type="text"
                                  placeholder={expression.placeholder}
                                  onChange={e => {
                                    const fieldsCopy = { ...values.email_doc_fields_extractors_rule };
                                    fieldsCopy[fe][i].value = e.target.value;
                                    setFieldValue('email_doc_fields_extractors_rule', fieldsCopy);
                                  }}
                                  value={expression.value}
                                />
                                <Badge
                                  colorScheme="red"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    const fieldsCopy = { ...values.email_doc_fields_extractors_rule };
                                    fieldsCopy[fe] = fieldsCopy[fe].filter((_, index) => index !== i);
                                    setFieldValue('email_doc_fields_extractors_rule', fieldsCopy);
                                  }}
                                >
                                  &times;
                                </Badge>
                              </HStack>
                              <Text pl="8px" fontSize="xs" as="sup">
                                {expression.type}
                              </Text>
                            </VStack>
                          ))}
                        </HStack>
                      </Field>
                    ))}
                  </Box>
                )}
              </Stack>
            </DialogBody>
            <DialogFooter borderTop="1px solid #dcdfe3">
              <Checkbox
                marginRight="auto"
                checked={preventClosingModalOnSubmit}
                onChange={() => setPreventClosingModalOnSubmit(!preventClosingModalOnSubmit)}
              >
                Prevent closing modal on submit
              </Checkbox>
              {values.created && (
                <Button type="button" onClick={() => removeDocumentType()} marginRight="16px" backgroundColor="red.500">
                  Remove
                </Button>
              )}
              <Button type="submit" colorScheme="actionPrimary" disabled={!isValid}>
                {!values.created ? 'New' : 'Save'}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </DialogRoot>
    </Box>
  );
};
