import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Center, Flex, IconButton, Text } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';
import DashboardSectionTile from '../../../components/core/DashboardSectionTile';
import { ReactComponent as UploadDragIconSVG } from '../../../assets/upload-drag.svg';
import { ReactComponent as AttachmentIconSVG } from '../../../assets/attachment.svg';
import { ReactComponent as TrashOutlineIconSVG } from '../../../assets/trash-outline-icon.svg';
import { Alert } from '@/components/ui/alert';
import { AccordionItem, AccordionItemContent, AccordionItemTrigger, AccordionRoot } from '@/components/ui/accordion';
import { MdDownload } from 'react-icons/md';

const DataImportChooseData = ({
  dataImportList,
  onClickDownloadTemplate,
  onSubmitImportFile,
  uploadFileErrorMessage,
  setUploadFileErrorMessage,
  selectedFiles,
  setSelectedFiles,
}) => {
  const onDropImportFile = files => {
    setSelectedFiles(files);
  };

  const removeSelectedFile = index => {
    setSelectedFiles(selectedFiles.filter((_, idx) => index !== idx));
  };

  return (
    <>
      <DashboardSectionTile title="Importing Data" padding="22px 16px 49px 15px">
        <Box marginLeft="16px !important" marginTop="8px !important">
          <Text fontWeight="400" color="#718096" marginLeft="3px">
            <i>You can import up to 500 records at a time</i>
          </Text>
          {dataImportList ? (
            <Flex marginTop="39px !important">
              <Box>
                <Text fontWeight="500" color="#718096">
                  What kind of data are you importing?
                </Text>

                <AccordionRoot
                  borderWidth="1px"
                  width="389px"
                  borderRadius="12px"
                  marginTop="11px"
                  overflow="auto"
                  defaultIndex={[0]}
                  collapsible
                >
                  {dataImportList?.map((item, index) => (
                    <AccordionItem
                      key={item.id}
                      value={item.id}
                      borderTopWidth={index === 0 ? '0px' : '1px'}
                      borderBottomWidth="0px !important"
                    >
                      <AccordionItemTrigger
                        padding="10px 17px"
                        backgroundColor="#FFFFFF"
                        fontWeight="500"
                        fontSize="16px"
                        _focus={{ outline: 'none' }}
                      >
                        <Text as="span" flex="1" textAlign="left">
                          {item.name}
                        </Text>
                      </AccordionItemTrigger>
                      <AccordionItemContent paddingY="10px" borderTopWidth="1px" display="flex" justifyContent="center">
                        <Button
                          onClick={() => onClickDownloadTemplate(item)}
                          variant="outline"
                          fontWeight="500"
                          borderWidth="2px"
                          height="36px"
                          borderRadius="8px"
                          borderColor="black" 
                          color="black"
                        >
                          <MdDownload />
                          Download Excel Template
                        </Button>
                      </AccordionItemContent>
                    </AccordionItem>
                  ))}
                </AccordionRoot>
              </Box>
              <Box marginLeft="41px">
                <Text fontWeight="500" color="#718096">
                  Upload Excel file here:
                </Text>
                <Flex
                  backgroundColor="#FFFFFF"
                  borderWidth="1px"
                  borderRadius="12px"
                  marginTop="11px"
                  padding="16px 21px"
                >
                  <Dropzone
                    className="document-dropzone"
                    activeClassName="excel-dropzone-active"
                    onDrop={onDropImportFile}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <Center flexDirection="column" {...getRootProps()} className="document-dropzone">
                        <input {...getInputProps()} />
                        <Box color="actionSecondary.500">
                          <UploadDragIconSVG />
                        </Box>
                        <Text fontSize="14px" marginTop="20px">
                          Click or drag file to this area to upload
                        </Text>
                      </Center>
                    )}
                  </Dropzone>

                  {selectedFiles.length > 0 && (
                    <Box marginLeft="40px">
                      <Text fontSize="16px" fontWeight="500">
                        Uploaded File:
                      </Text>
                      {selectedFiles.map((uploadedFile, index) => (
                        <Box borderBottomWidth="1px" width="345px" marginTop="20px" paddingBottom="13px">
                          <Flex alignItems="center">
                            <AttachmentIconSVG />
                            <Text marginLeft="9px">{uploadedFile.name}</Text>
                            <IconButton
                              marginLeft="auto"
                              color="red"
                              variant="unstyled"
                              minWidth="18px"
                              height="fit-content"
                              onClick={() => removeSelectedFile(index)}
                            >
                              <TrashOutlineIconSVG />
                            </IconButton>
                          </Flex>
                        </Box>
                      ))}
                    </Box>
                  )}
                </Flex>
                {uploadFileErrorMessage && (
                  <Alert
                    status="error"
                    // variant="left-accent"
                    borderRadius="6px"
                    marginTop="25px"
                    title={uploadFileErrorMessage}
                    closeable
                    onClose={() => setUploadFileErrorMessage('')}
                  />
                )}
              </Box>
            </Flex>
          ) : (
            <Text marginTop="20px" fontWeight="600">
              No import config available
            </Text>
          )}
        </Box>
      </DashboardSectionTile>
      <Flex>
        <Button
          colorScheme="actionPrimary"
          fontSize="16px"
          height="40px"
          width="126px"
          fontWeight="900"
          onClick={() => onSubmitImportFile(selectedFiles)}
          marginLeft="auto"
          marginTop="39px"
          disabled={selectedFiles.length < 1}
        >
          Submit
        </Button>
      </Flex>
    </>
  );
};

DataImportChooseData.propTypes = {
  dataImportList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClickDownloadTemplate: PropTypes.func,
  onSubmitImportFile: PropTypes.func,
  uploadFileErrorMessage: PropTypes.string,
  setUploadFileErrorMessage: PropTypes.func,
  selectedFiles: PropTypes.func,
  setSelectedFiles: PropTypes.func,
};

export default DataImportChooseData;
