import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ChakraProvider } from '@chakra-ui/react';
// import { getTenantTheme } from '../../theme_old/theme';
import { useDispatch, connect } from 'react-redux';
import { retrieveTheme, retrieveThemeInEditorMode } from '../../actions/actions_theme';
import { system } from '../../theme';
// import { ThemeProvider } from 'next-themes';

const ChakraProviderContainer = ({ children, theme, user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (user && ['read_theme', 'write_theme'].every(permission => user.role.access_list.includes(permission)))
      dispatch(retrieveThemeInEditorMode());
    else dispatch(retrieveTheme());
  }, [user]);

  useEffect(() => {
    if (theme.is_loaded) {
      // Accessing favicon element
      const favicon = document.getElementById('favicon');
      favicon.href = window.location.hostname.includes('.agilechain')
        ? 'https://s3.amazonaws.com/prod-cfc-boab-public-bucket/CFC_logo_aa1027a9.png'
        : 'img/BOAB_Blue.png';
      document.title = window.location.hostname.includes('.agilechain') ? 'AgileChain' : 'BOAB';
      // setState({ themeFromApi: tenantTheme });
    }
  }, [theme.is_loaded]);

  // useEffect(() => {
  //   if (theme.is_edited) {
  //     const tenantTheme = getTenantTheme(theme.data);
  //     setState({ themeFromApi: tenantTheme });
  //     dispatch(resetThemeEditedFlag());
  //   }
  // }, [theme.is_edited]);

  return (
    <ChakraProvider value={system}>
      {/* <ThemeProvider attribute="class" disableTransitionOnChange> */}
      {children}
      {/* </ThemeProvider> */}
    </ChakraProvider>
  );
};

ChakraProviderContainer.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({
    is_loaded: PropTypes.bool.isRequired,
    is_edited: PropTypes.bool.isRequired,
    data: PropTypes.shape({}).isRequired,
  }).isRequired,
  user: PropTypes.instanceOf(Object).isRequired,
};

const mapStateToProps = state => {
  return {
    theme: state.theme,
    user: state.user,
  };
};

export default connect(mapStateToProps)(ChakraProviderContainer);
