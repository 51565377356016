import moment from 'moment';

const DB_DATE_FORMAT = 'YYYY-MM-DD';

export function printToday(dateFormat = DB_DATE_FORMAT) {
  return moment().format(dateFormat);
}

export function epochToJsDate(ts) {
  // ts = epoch timestamp
  // returns date obj
  // ts expected to be int
  return new Date(parseInt(ts, 10));
}

export function convertToLocalISOString(date) {
  /*
  1. Convert the local time zone offset from minutes to milliseconds
  2. subtract the offset from tLocal 
  3. create shifted Date object
  4. convert to ISO format
  5. drop the zone 
  * */
  const tzoffset = date.getTimezoneOffset() * 60000; // offset in milliseconds
  return new Date(date - tzoffset).toISOString().slice(0, -1);
}
